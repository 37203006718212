import React, { useState, useEffect, useRef, useCallback, useMemo  } from 'react';
import verificareToken from './VerificareToken';
import PageExpander from './PageExpander'; //expandare
import { DataGridPro, useGridApiRef, useGridApiContext, GridToolbarContainer, GridToolbarExportContainer, GridPrintExportMenuItem, GridCsvExportMenuItem, GridToolbarColumnsButton, GridToolbarDensitySelector, GridToolbarFilterButton, roRO, getGridStringOperators,   GridColDef,   GridFilterOperator, GridPrintGetRowsToExportParams, gridFilteredSortedRowIdsSelector, selectedGridRowsSelector, GridRowId,   } from '@mui/x-data-grid-pro'; 
import { ExportInExcel } from './ExportInExcel';
import { Dialog, Snackbar, Alert, Backdrop, DialogTitle, DialogContent, DialogActions, FormControlLabel, Button, Tooltip, FormControl, InputLabel, CircularProgress, Popover, List, ListItem } from '@mui/material';
import NotificationsIcon from '@material-ui/icons/Notifications';
import RemoveRedEyeIcon from '@material-ui/icons/RemoveRedEye';
import InfoIcon from '@material-ui/icons/Info';
import { LicenseInfo } from '@mui/x-license-pro'; 
import BorderColorIcon from '@mui/icons-material/BorderColor'; 
import TextField from '@mui/material/TextField';
import Switch from '@mui/material/Switch';
import Select from '@mui/material/Select';
import Menu from "@mui/material/Menu";
import MenuItem from '@mui/material/MenuItem'; 
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import Swal from 'sweetalert'; 
import LinearProgress from '@mui/material/LinearProgress';
import { styled } from '@mui/system';
import CreareDosar from './creareDosar';
import PostAddIcon from '@mui/icons-material/PostAdd';
import Autocomplete from '@mui/material/Autocomplete';
import DriveFileMoveIcon from '@mui/icons-material/DriveFileMove';
import MoveUpIcon from '@mui/icons-material/MoveUp';
import DeleteIcon from '@mui/icons-material/Delete';
import Badge from '@mui/material/Badge'; 
import SyncIcon from '@mui/icons-material/Sync';
import TimerIcon from '@mui/icons-material/Timer';
import ModalComponentIstoric from './ModalComponentIstoric';
import CopyrightIcon from '@mui/icons-material/Copyright';
import DrepturiUtilizator from './utilizator/drepturiUtilizator'; 
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation';
import FolderDeleteIcon from '@mui/icons-material/FolderDelete';
import RecuperareDosareSterse from './RecuperareDosareSterse';  
import ExportDosare from './ExportDosare';
import ExportDosareExcel from './ExportDosareExcel';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import usePreferinteUtilizator from './utilizator/preferinteUtilizatorHook'; 
import GridOnIcon from '@mui/icons-material/GridOn';
import GridOffIcon from '@mui/icons-material/GridOff';
import DateFilterComponent from './DateFilterComponent';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { format, parseISO, parse } from 'date-fns';
import { ro } from 'date-fns/locale';
import { startOfDay, endOfDay } from 'date-fns';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import NotificareDosar from './dosar/notificareDosar';
import StareDosar from './dosar/stareDosar'; 
import EditNotificationsIcon from '@mui/icons-material/EditNotifications';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
import TimerOffIcon from '@mui/icons-material/TimerOff';
import SyncDisabledIcon from '@mui/icons-material/SyncDisabled'; 
import AssistantIcon from '@mui/icons-material/Assistant';
import HelpDialog from './HelpDialog';
import debounce from 'lodash/debounce';
import FolderSpecialIcon from '@mui/icons-material/FolderSpecial';
import FolderOffIcon from '@mui/icons-material/FolderOff';
import copy from 'copy-to-clipboard';
import { useSound } from './sunete';
import { NotificationAdd } from '@mui/icons-material';
import InchidereDosare from './InchidereDosare';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import UnarchiveIcon from '@mui/icons-material/Unarchive';

LicenseInfo.setLicenseKey('ac62ac97b94addeb6dbf2b2473e35ecbTz05MjE3MSxFPTE3NDk2MjQ2NjIwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=');
function ListaDosareArhivate() { 
 
  const { preferinte } = usePreferinteUtilizator();
  const colorDCL = preferinte.colorDCL; //Dosare Consultanță în lucru
  const colorDCF = preferinte.colorDCF;  //Dosare Consultanță finalizate
  //const colorDIL = preferinte.colorDIL;  //Dosare Instanță în lucru
  const colorDIF = preferinte.colorDIF;  //Dosare Instanță finalizate  
  const colorDA = preferinte.colorDA;   //Dosare cu accesorii 
 
  //start expandare
  const [isWide, setIsWide] = useState(false); // Inițializează starea `isWide`
  const handleToggle = (isWideState) => {
    setIsWide(isWideState); 
  };
  //end expandare
const currentDate = new Date();
const formattedDate = currentDate.toLocaleDateString('ro-RO').replace(/\//g, '-');
const formattedTime = currentDate.toLocaleTimeString('ro-RO', { hour12: false }).replace(/:/g, '-');

const fileNameExcel = `JuristPRO - Export EXCEL - ${formattedDate} - ${formattedTime}`;
const fileNameCSV = `JuristPro - CSV Export lista dosare - ${formattedDate} - ${formattedTime}`;
 
const { playSound } = useSound();

  //reactualizez starea lui authChange - verific starea lui isLoggedIn sa vad daca mai e logat
  window.dispatchEvent(new Event('authChange'));
  
      //aduc hook-ul de drepturi
       const { areDrept } = DrepturiUtilizator();
       const [openModalIstoric, setOpenModalIstoric] = useState(false);
       const DreptDeStergereDosare = 'DreptDeStergereDosare';
       const DreptDeRealocareDosareLaUnAltUtilizator = 'DreptDeRealocareDosareLaUnAltUtilizator';
       const DreptDeAdaugareDosare = 'DreptDeAdaugareDosare';
       const AdaugareGrupuriDeDosare = 'AdaugareGrupuriDeDosare'; 
       //const VizualizareDosare = 'VizualizareDosare'; 
       const AdaugareNotificariUtilizatoriPeDosar = 'AdaugareNotificariUtilizatoriPeDosar'; 
       
       const [rowMonitorizat, setRowMonitorizat] = useState(null);
       const [rowSincronizat, setRowSincronizat] = useState(null);
       const [dosarInstantaSingur, setDosarInstantaSingur] = useState(null);
       
       //console.log('DreptDeRealocareDosareLaUnAltUtilizator', areDrept(DreptDeRealocareDosareLaUnAltUtilizator));
       //console.log('DreptDeAdaugareDosare', areDrept(DreptDeAdaugareDosare));
       //console.log('AdaugareGrupuriDeDosare', areDrept(AdaugareGrupuriDeDosare));
       
       //const [value, setValue] = useState(0);
       const [anchorElDosare, setAnchorElDosare] = useState(null);
       const [showDosareNoi, setShowDosareNoi] = useState(false);
       const [showDosareFinalizate, setShowDosareFinalizate] = useState(false);
       
       const handleMenuClick = (event) => {
        setAnchorElDosare(event.currentTarget);
       };
       
       const handleMenuClose = () => {
        setAnchorElDosare(null);
       };
       
       const handleShowDosareNoi = () => {
         setShowDosareNoi(true);
         setShowDosareFinalizate(false);
         setAnchorElDosare(null);
       };
       
       const handleShowDosareFinalizate = () => {
         setShowDosareFinalizate(true);
         setShowDosareNoi(false);
         setAnchorElDosare(null);
       };
       
      const handleShowAllDosare = () => {
        setShowDosareNoi(false);
        setShowDosareFinalizate(false);
        handleMenuClose();
      }; 

       const [openAutocomplete, setOpenAutocomplete] = useState(false);
       const autocompleteRef = useRef(null);
       useEffect(() => {
        const handleClickOutside = (event) => {
          if (autocompleteRef.current && !autocompleteRef.current.contains(event.target)) {
            setOpenAutocomplete(false);
          }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
          document.removeEventListener('mousedown', handleClickOutside);
        };
      }, []);
      const handleUserSelect = (event, newValue) => {
        if (newValue) {
          handleReassignUser(newValue.id);
          setOpenAutocomplete(false);
        }
      };

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [selectedCellParams, setSelectedCellParams] = useState(null);
  const { token } = verificareToken(); 
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({
    numardosarfinal: true,
    deninstanta: true,
    denumireobiect: true,
    denumirestadiu: true,
    dosar_groups: true,
    client: true,
    dataum: true,
    datatermen: true,
    materie_obiect_dosar: true,
    descrieresolutie: true,
    user_name: true,
    numarintern: false,
    dataactualizare: false,
    titlefinalizat: false,
    datacreare: false,
    datastadiu: false,
    data_termen: false,
    parti_adverse: false,
    parti_dosar: false,
    adresaclientdosar: false,
    adresaparteadversa: false,
    calitateclientdosar: false,
    CUIclient: false,
    cui_parti_adverse: false,
    RECOMclientdosar: false,
    RECOMparteadversa: false,
    sentintaprimita: false,
    nrstadii: false,
    materiedosar: false,
    txtum: false,
    solutiedosar: false,
    datafinalizare: false,
    dosarinstanta: false,
    descsolutie: false,
    favorabil: false,
    comentarii: false,
    staredosar: false,
    emailuser: false,
    denumireuser: false,
    detaliiuser: false,
    telefonuser: false,
    accesorii: false,
    monitorizat: false,
    utilizator: false,
    dosar_adaugat_la_data: false,
    dosar_modificat_la_data : false,
  });

  const [seIncarcaPagina, setSeIncarcaPagina] = useState(false);
  const [loadingPartiDosar, setLoadingPartiDosar] = useState(false);
  const [loadingTermeneDosar, setLoadingTermeneDosar] = useState(false);
  const [selectedRowId, setSelectedRowId] = useState(null);

  const [accesoriiFiltru, setAccesoriiFiltru] = React.useState('');
  const [monitorizatFiltru, setMonitorizatFiltru] = React.useState('');
  
  const [isNotificareAdmin, setIsNotificareAdmin] = useState(false);
  //mutare dosar/e la alt user:
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [users, setUsers] = useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null); 
  const [anchorEluser, setAnchorEluser] = React.useState(null); 
  const [anchorEluser2, setAnchorEluser2] = React.useState(null); 

  const [showInstanta, setShowInstanta] = useState(true);
  const [showConsultanta, setShowConsultanta] = useState(false);
  
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [dateFilterType, setDateFilterType] = useState('datacreare'); 

//legenda culori
const [legendaEl, setLegendaEl] = useState(null);

const handleLegenda = (event) => {
  setLegendaEl(event.currentTarget);
};

const handleCloseLegenda = () => {
  setLegendaEl(null);
};

const openLegenda = Boolean(legendaEl);
const idLegenda = openLegenda ? 'simple-popover' : undefined;

  const [isExportDosareOpen, setIsExportDosareOpen] = useState(false);
  const [isExportDosareExcelOpen, setIsExportDosareExcelOpen] = useState(false); 

  const handleOpenExportDosare = () => {
    let text;
  
    if (selectedRows2.length > 10) {
      text = `Sunteți sigur că doriți imprimarea celor ${selectedRows2.length} dosare selectate? Acest proces poate dura mai mult timp.`;
    } else if (selectedRows2.length > 0) {
      text = `Sunteți sigur că doriți imprimarea celor ${selectedRows2.length} dosare selectate?`;
    } else {
      text = `Nu există dosare selectate! Generarea listei cu ${filteredDosare.length} dosare poate dura câteva minute. Continuați?`;
    }
  
    Swal({
      title: 'Imprimare detaliată',
      text: text,
      icon: 'warning',
      buttons: ['Anulează', 'Da, continui!'],
      dangerMode: true,
    }).then((willContinue) => {
      if (willContinue) {
        handleOpenExportDosareOK();
      }
    });
  };
  
  const handleOpenExportDosareOK = () => {
    setIsExportDosareOpen(true);
  };

  const handleCloseExportDosare = () => {
    setIsExportDosareOpen(false);
  };

  const handleExporturiGenerate = () => {
    window.location.href = "/export-dosare";
  };
  const handleOpenExportDosareExcel = () => {
    let text;
  
    if (selectedRows2.length > 10) {
      text = `Sunteți sigur că doriți exportul celor ${selectedRows2.length} dosare selectate? Acest proces poate dura mai mult timp.`;
    } else if (selectedRows2.length > 0) {
      text = `Sunteți sigur că doriți exportul celor ${selectedRows2.length} dosare selectate?`;
    } else {
      text = `Nu există dosare selectate! Generarea listei cu ${filteredDosare.length} dosare poate dura câteva minute. Continuați?`;
    }
  
    Swal({
      title: 'Export detaliat Excel',
      text: text,
      icon: 'warning',
      buttons: ['Anulează', 'Da, continui!'],
      dangerMode: true,
    }).then((willContinue) => {
      if (willContinue) {
        handleOpenExportDosareExcelOK();
      }
    });
  };
  
  const handleOpenExportDosareExcelOK = () => {
    setIsExportDosareExcelOpen(true);
  };

  const handleCloseExportDosareExcel = () => {
    setIsExportDosareExcelOpen(false);
  };



  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClickuser = (event) => {
    setAnchorEluser(event.currentTarget);
  };
  const handleClickuser2 = (event) => {
    setAnchorEluser2(event.currentTarget);
  };

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}users?users_from_all_common_groups=1`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          }
        });
        
        const {data} = await response.json();
        setUsers(data.users);
      } catch (error) {
        console.error("Eroare la preluarea utilizatorilor", error);
      }
    };
 
    fetchUsers();
 }, [token]);
 

 const [utilizatori, setUtilizatori] = useState([]);

 useEffect(() => { 
  const fetchUtilizatori = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}users`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      setUtilizatori(data.data.users);
      //console.log('utilizatori: ', utilizatori);
    } catch (error) {
      console.error('Problemă la preluare utilizatori: ', error);
    }
  };
  fetchUtilizatori();
}, [isNotificareAdmin, token]);


//verificare dosare instanta

const [isDosarInstanta, setIsDosarInstanta] = useState(false);
const checkIsDosarInstanta = (selectedRows) => {
 if (selectedRows.length === 0) {
   setIsDosarInstanta(false);
 } else {
   const allInstanta = selectedRows.every(row => row.is_dosar_instanta === 1);
   setIsDosarInstanta(allInstanta);
 }
};

const [isInchidereOpen, setIsInchidereOpen] = useState(false); 
 
const [selectedUsers, setSelectedUsers] = useState([]);
 
 
  //CREARE DOSAR
 
  const [openDialog, setOpenDialog] = useState(false);
  

  //CONTEXT MENIU
  const [contextMenu, setContextMenu] = React.useState(null);
  const [rowId, setRowId] = useState(null);
  const [idDosarIstoric, setIdDosarIstoric] = useState(null);
  
  const handleContextMenu = (event, params) => {
    setSelectedGroupId(null); 
    event.preventDefault();
    setSelectedRow(Number(event.currentTarget.getAttribute("data-id")));
    setRowId(Number(event.currentTarget.getAttribute("data-id")));
    setContextMenu(
      contextMenu === null
        ? { mouseX: event.clientX - 2, mouseY: event.clientY - 4 }
        : null
    );
    const rowId = Number(event.currentTarget.getAttribute("data-id"));
    setSelectedRow(rowId);
    console.log("randul selectat: ", rowId); 
      
    const selectedRows = apiRef2.current.getSelectedRows();
    const DosarInst = event.currentTarget.classList.contains("data-is-dosar-instanta-1");
    setDosarInstantaSingur(DosarInst);
    console.log('DosarInst', DosarInst);
    
        // Selectează rândul pe care s-a dat click dreapta dacă nu este deja selectat
        if (!selectedRows.has(rowId)) {
          apiRef2.current.selectRow(rowId, true, true);
        }

  setSelectedCellParams(params);
  };

  const handleOpenModalIstoric = (id) => {
    setOpenModalIstoric(true);
    setIdDosarIstoric(id);
    setRowId(null);  
    setContextMenu(null);
  };
  
  const handleClose = () => {
    setContextMenu(null);
  }; 
 
  //fix pt diacritice filtre de la MUI pe GIT
  const stringFilterOperators = getGridStringOperators();

  const removeDiacritics = (str) => {
    if (typeof str !== 'string') {
     // console.error('removeDiacritics a fost apelat cu un non-string:', str);
      return str; 
    }
    return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  };
  

  const wrapFilterOperator = useCallback((operator: GridFilterOperator) => {
    const getApplyFilterFn: GridFilterOperator["getApplyFilterFn"] = (
      filterItem,
      column
    ) => {
      const innerFilterFn = operator.getApplyFilterFn(
        {
          ...filterItem,
          value: removeDiacritics(filterItem.value)
        },
        column
      );
      if (!innerFilterFn) {
        return innerFilterFn;
      }
  
      return (params) => {
        const newParams = {
          ...params,
          value: removeDiacritics(params.value)
        };
        return innerFilterFn(newParams);
      };
    };
  
    return {
      ...operator,
      getApplyFilterFn
    };
  }, []);

  const handleAccesoriiChange = (event) => {
    setAccesoriiFiltru(event.target.value);
  };

  const handleMonitorizatChange = (event) => {
    setMonitorizatFiltru(event.target.value);
  };
  
  
  const handleCreateNotificare = async (tipNotificare, idDosar2) => {
    if (selectedRow && formData.descriere !== '') {
      const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          idDosar: idDosar2, 
          descriere: formData.descriere,
          deladata: formData.deladata,
          panaladata: formData.panaladata,
          notificare: formData.notificare,
          via_sms: formData.via_sms,
          via_email: formData.via_email,
          via_push: formData.via_push,
          reminder: formData.reminder,
          recurent: formData.recurent,
          tipNotificare,
          ...(isNotificareAdmin && { user_id: selectedUsers.map((user) => user.id) }),
        }),
      };
  
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}notificari`, requestOptions);
        if (response.ok) {
          Swal('Succes', 'Notificare adăugată cu succes!', 'success');
          handleCloseModal();
          setFormData({
            descriere: '',
            panaladata: null,
            notificare: 0,
            via_sms: 0,
            via_email: 0,
            via_push: 0,
            reminder: 0,
            recurent: 0,
          });
        } else {
          Swal('Atenționare', 'A apărut o eroare la adăugarea notificării!', 'warning');
        }
      } catch (error) {
        Swal('Eroare de rețea', 'A apărut o eroare de rețea!', 'warning');
        console.error('A apărut o eroare de rețea:', error);
      }
    } else {
      Swal('Atenționare', 'Introduceți o descriere pentru notificare!', 'warning');
    }
  };
  
  
  const [partiDosar, setPartiDosar] = useState([]);
  const fetchDetaliiPartiDosar = async (id) => {
    setLoadingPartiDosar(true);
    try {
      const urlInfoStadii = `${process.env.REACT_APP_API_URL}infoStadiiDosar/${id}`;
      const responseInfoStadii = await fetch(urlInfoStadii, {
        method: 'GET',  
        headers: {
          'Authorization': `Bearer ${token}`,  
          'Content-Type': 'application/json'
        }
      });
  
      if (!responseInfoStadii.ok) {
        throw new Error(`HTTP error! status: ${responseInfoStadii.status}`);
      }
  
      const dataInfoStadii = await responseInfoStadii.json();
      const stadiuCurent = dataInfoStadii.data.stadiuCurent;
  
      const urlPartiDosar = `${process.env.REACT_APP_API_URL}infoPartiDosar/${stadiuCurent}`;
      const responsePartiDosar = await fetch(urlPartiDosar, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,  
          'Content-Type': 'application/json'
        }
      });
  
      if (!responsePartiDosar.ok) {
        throw new Error(`HTTP error! status: ${responsePartiDosar.status}`);
      }
  
      const dataPartiDosar = await responsePartiDosar.json();
      console.log(dataPartiDosar);  
      setPartiDosar(dataPartiDosar.data.partidosar);
  
    } catch (error) {
      console.error('Eroare la fetchDetaliiPartiDosar:', error.message);
    } finally {
      setLoadingPartiDosar(false);  
    }
  };
  
  const [termeneDosar, setTermeneDosar] = useState([]);
  const fetchTermeneDosar = async (id) => {
    setLoadingTermeneDosar(true);
    try {
      const urlTermeneDosar = `${process.env.REACT_APP_API_URL}infoTermene/${id}`;
      const responseTermene = await fetch(urlTermeneDosar, {
        method: 'GET',  
        headers: {
          'Authorization': `Bearer ${token}`,  
          'Content-Type': 'application/json'
        }
      });
  
      if (!responseTermene.ok) {
        throw new Error(`HTTP error! status: ${responseTermene.status}`);
      } 
      const dataTermeneDosar = await responseTermene.json();
      console.log(dataTermeneDosar);  
      setTermeneDosar(dataTermeneDosar.data.termene);
  
    } catch (error) {
      console.error('Eroare la fetchTermeneDosar:', error.message);
    } finally {
      setLoadingTermeneDosar(false);  
    }
  };
  
  const initialState = {
    descriere: '',
    panaladata: new Date(),
    notificare: 0,
    via_sms: 0,
    via_email: 0,
    via_push: 0,
    reminder: 1,
    recurent: 0,
  };

const [openModal, setOpenModal] = useState(false);
const [formData, setFormData] = useState(initialState);
const handleOpenModal = (idDosarTrimis) => {
  setOpenModal(true);
  setFormData({
    descriere: '',
    panaladata: new Date(),
    notificare: 0,
    via_sms: 0,
    via_email: 0,
    via_push: 0,
    reminder: 1,
    recurent: 0,
  });
  
  setSelectedRowId(idDosarTrimis); 
};
const handleCloseModal = () => {
  setOpenModal(false);
  setIsNotificareAdmin(false);
};


const handleDateChangeStart = (newDate) => {
  if (newDate) {
    const formattedDate = format(newDate, 'dd.MM.yyyy');
    setStartDate(formattedDate);  
    console.log('formattedDateStart', formattedDate);
  } else {
    setStartDate(null);  
  }
};

const handleDateChangeEnd = (newDate) => {
  if (newDate) {
    const formattedDate = format(newDate, 'dd.MM.yyyy');
    setEndDate(formattedDate);  
    console.log('formattedDateEnd', formattedDate);
  } else {
    setEndDate(null);  
  }
};

const handleInputChange = (event) => {
  const { name, value } = event.target;
  setFormData((prevFormData) => ({
    ...prevFormData,
    [name]: value,
  }));
};

useEffect(() => {
  if (openModal) {
    setFormData((prevFormData) => ({
      ...prevFormData,
      panaladata: new Date(),
    }));
  }
}, [openModal]);

  const [dosare, setDosare] = React.useState([]);
  const [showDeleteButton, setShowDeleteButton] = useState(false);
  const [showDeleteButton2, setShowDeleteButton2] = useState(false);
 
  const [isLoading, setIsLoading] = React.useState(false);

  const [open, setOpen] = React.useState(false);
  const [selectedRow, setSelectedRow] = React.useState(null);
  const [selectedRows, setSelectedRows] = React.useState([]);
  const [selectedRows2, setSelectedRows2] = React.useState([]);

  const [hoveredRow, setHoveredRow] = useState(null);
 
  const handleRowHover = (params) => {
    setHoveredRow(params.row.id);
  };
  
  const fetchData = React.useCallback(async () => {
      const { token } = verificareToken();
    setIsLoading(true);
    try {
      let url = `${process.env.REACT_APP_API_URL}dosare?arhivat=1`;
      if (accesoriiFiltru !== "") {
        url += `&accesorii=${accesoriiFiltru}`;
      }
      if (monitorizatFiltru !== "") {
        url += `&monitorizat=${monitorizatFiltru}`;
      }

      const response = await fetch(url, {

        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      //console.log("Date originale:", data);  
      
  
      if (data && data.data && Array.isArray(data.data.dosare)) {
        const dosareWithCustomFields = data.data.dosare.map(dosar => {
          if (dosar.custom_fields) {
            const cleanedCustomFields = dosar.custom_fields.replace(/[\r\n\t]/g, "");
            const customFields = JSON.parse(cleanedCustomFields);
            const customData = customFields.reduce((acc, field) => {
              acc[field.name] = field.value;  
              return acc;
            }, {});
  
            return { ...dosar, ...customData };
          }
          return dosar;  
        });
  
        //console.log("Dosare cu custom fields:", dosareWithCustomFields);  
        setDosare(dosareWithCustomFields);
      } else {
        console.error('Format date incorect:', data);
      }
 
      //console.log('URL fetch:', `${url}`);
      //console.log('Date aduse din API:', data);
      //console.log('Mesaj de la server:', data.message);
      //console.log('Status de la server:', data.status);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }, [accesoriiFiltru, monitorizatFiltru]);
  
  
  React.useEffect(() => {
    fetchData();
  }, [fetchData]);
  
  React.useEffect(() => { 
    if (selectedRows.length > 0) {
      setShowDeleteButton(true);
    } else {
        setShowDeleteButton(false);
    }

    if (selectedRows.length > 1) {
      setShowDeleteButton2(true);
    } else {
        setShowDeleteButton2(false);
    }

    
  }, [selectedRows]);



  const handleMonitorizareDosarMeniu = () => {
    let text;
  
    if (selectedRows2.length > 1) {
      text = `Sunteți sigur că doriți monitorizarea celor ${selectedRows2.length} dosare selectate?`;
    } else {
      text = `Sunteți sigur că doriți monitorizarea dosarului selectat?`;
    }  
    Swal({
      title: 'Monitorizare',
      text: text,
      icon: 'warning',
      buttons: ['Anulează', 'Da, continui!'],
      dangerMode: true,
    }).then((willContinue) => {
      if (willContinue) {
        handleMonitorizareDosarMeniuOK();
      }
    });
  };

  const handleMonitorizareDosarMeniuOK = async () => {
    setSeIncarcaPagina(true);
      let method;
      let monitorizareAPIURL;
      let mesajAlerta;
 
// verific daca am un dosar selectat la click dreapta, altfel le iau din selectedRows daca > 1
  //const idUriPtMonitorizare = rowId !== null && selectedRows.length <= 1 ? `id_dosar[]=${rowId}` : selectedRows.map(id => `id_dosar[]=${id}`).join('&');
    
  const idUriPtMonitorizare = rowId !== null && selectedRows.length <= 1 ? [rowId] : selectedRows;
    monitorizareAPIURL = `${process.env.REACT_APP_API_URL}monitorizari?monitorizat=1`;
    method = 'POST';
    mesajAlerta = selectedRows.length > 1 ?
        'Dosarele au fost adăugate la lista de monitorizare' :
        'Dosarul a fost adăugat la lista de monitorizare';

    const monitorizareAPIResponse = await fetch(monitorizareAPIURL, {
    method: method,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({ ids: idUriPtMonitorizare }),  
  });

    if (monitorizareAPIResponse.ok) {
      // Construiește mesajul de alertă bazat pe acțiunea efectuată
      setSeIncarcaPagina(false);
      Swal('Succes', mesajAlerta, 'success');
      fetchData();
    
      // Actualizează starea de monitorizat pentru dosarele selectate
       
    }
     else {
      Swal('Atenționare', 'A apărut o eroare la monitorizarea dosarului!', 'warning');
      setSeIncarcaPagina(false);
    }
     
    setRowId(null);  
    setContextMenu(null);

  };

  const handleMonitorizareDosar = useCallback(async (idDosar) => {
    const dosar = dosare.find((dosar) => dosar.id === idDosar);
    if (!dosar) {
      return;
    }

    const monitorizat = dosar.monitorizat;

    const monitorizareAPIURL = `${process.env.REACT_APP_API_URL}monitorizari/${idDosar}?monitorizat=${monitorizat === 1 ? 0 : 1}`;
    const monitorizareAPIResponse = await fetch(monitorizareAPIURL, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });

    if (monitorizareAPIResponse.ok) {
      const mesajAlerta = monitorizat === 1 ? 'Dosarul a fost eliminat din lista de monitorizare' : 'Dosarul a fost adăugat la lista de monitorizare';
      Swal('Succes', mesajAlerta, 'success');
      if (monitorizat === 1) {
        setRowMonitorizat(0);
      } else {
        setRowMonitorizat(1);
      }
      fetchData();
      const updatedDosare = dosare.map((dosar) => {
        if (dosar.id === idDosar) {
          return {
            ...dosar,
            monitorizat: monitorizat === 1 ? 0 : 1,
          };
        }
        return dosar;
      });
      setDosare(updatedDosare);
    } else {
      Swal('Atenționare', 'A apărut o eroare la monitorizarea dosarului!', 'warning');
    }
    
}, [dosare, fetchData, token]);

  
//copiere date tabel
const triggerCopy = () => {
  const copyEvent = new ClipboardEvent('copy', {
    dataType: 'text/plain',
    data: '',
  });

  document.dispatchEvent(copyEvent);
};

const handleCopySelectedRows = () => {
  const selectedRows = apiRef2.current.getSelectedRows();
  const allColumns = apiRef2.current.getAllColumns().filter(col => col.field !== '__check__');
  const selectedData = Array.from(selectedRows.values()).map(row => {
    return allColumns.map(col => row[col.field]).join('\t');  
  }).join('\n');  

  copy(selectedData);  
  
  setContextMenu(null);
  const rowCount = selectedRows.size;
  const message = rowCount === 1 ? 'Randul a fost copiat' : 'Randurile au fost copiate';
  setSnackbarMessage(message);
  setSnackbarOpen(true);
};

const handleSnackbarClose = () => {
  setSnackbarOpen(false);
};


const handleDezArhivareDosarMeniu = () => {
  let text;

  if (selectedRows2.length > 1) {
    text = `Sunteți sigur că doriți dezarhivarea celor ${selectedRows2.length} dosare selectate?`;
  } else {
    text = `Sunteți sigur că doriți dezarhivarea dosarului selectat?`;
  }  
  Swal({
    title: 'Dezarhivare',
    text: text,
    icon: 'warning',
    buttons: ['Anulează', 'Da, continui!'],
    dangerMode: true,
  }).then((willContinue) => {
    if (willContinue) {
      handleDezArhivareDosarMeniuOK();
    }
  });
};

const handleDezArhivareDosarMeniuOK = async () => {
  setSeIncarcaPagina(true);
    let method; 
    let mesajAlerta;
    const idUriPtArhivare = rowId !== null && selectedRows.length <= 1 ? [rowId] : selectedRows;

    const queryParams = idUriPtArhivare.map(id => `id_dosar[]=${id}`).join('&');
    
    const arhivareAPIURL = `${process.env.REACT_APP_API_URL}restore?dezarhivare=1&${queryParams}`;
    
    method = 'PATCH';
    mesajAlerta = selectedRows.length > 1 ?
        'Dosarele au fost dezarhivare din arhivă cu succes' :
        'Dosarul a fost dezarhivat din arhivă cu succes';

    const arhivareAPIResponse = await fetch(arhivareAPIURL, {
    method: method,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });

  if (arhivareAPIResponse.ok) {
 
    Swal('Succes', mesajAlerta, 'success');
    fetchData();
    setSeIncarcaPagina(false);
     
  }
   else {
    Swal('Atenționare', 'A apărut o eroare la dezarhivarea din arhivă a dosarului!', 'warning');
    setSeIncarcaPagina(false);
  }
   
  setRowId(null);  
  setContextMenu(null);

};

const handleDezArhivareDosarMeniu2 = (id) => {
  let text;

  text = `Sunteți sigur că doriți dezarhivarea din arhivă a dosarului selectat?`;
  Swal({
    title: 'Dezarhivare din arhivă',
    text: text,
    icon: 'warning',
    buttons: ['Anulează', 'Da, continui!'],
    dangerMode: true,
  }).then((willContinue) => {
    if (willContinue) {
      handleDezArhivareDosarMeniuOK(id);
    }
  });
};

const handleDezArhivareDosar = useCallback(async (idDosar) => {
  const dosar = dosare.find((dosar) => dosar.id === idDosar);
  if (!dosar) {
    return;
  }
 
  setSeIncarcaPagina(true);
  let method;
  let arhivareAPIURL;
  let mesajAlerta;

  const idUriPtArhivare = rowId !== null && selectedRows.length <= 1 ? [rowId] : selectedRows;
 
  arhivareAPIURL = `${process.env.REACT_APP_API_URL}arhivareDosare`;
  method = 'PATCH';
  mesajAlerta = 'Dosarul a fost recuperat din arhivă cu succes';

  const arhivareAPIResponse = await fetch(arhivareAPIURL, {
  method: method,
  headers: {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
  },
  body: JSON.stringify({ ids: idUriPtArhivare }),  
});

if (arhivareAPIResponse.ok) {

  Swal('Succes', mesajAlerta, 'success');
  fetchData();
  setSeIncarcaPagina(false);
   
}
 else {
  Swal('Atenționare', 'A apărut o eroare la dezarhivarea dosarului!', 'warning');
  setSeIncarcaPagina(false);
}
 
setRowId(null);  
setContextMenu(null);

}, [dosare, fetchData, token]);



  const handleSincronizareDosarMeniu = () => {
    let text;
  
    if (selectedRows2.length > 1) {
      text = `Sunteți sigur că doriți sincronizarea celor ${selectedRows2.length} dosare selectate?`;
    } else {
      text = `Sunteți sigur că doriți sincronizarea dosarului selectat?`;
    }  
    Swal({
      title: 'Sincronizare',
      text: text,
      icon: 'warning',
      buttons: ['Anulează', 'Da, continui!'],
      dangerMode: true,
    }).then((willContinue) => {
      if (willContinue) {
        handleSincronizareDosarMeniuOK();
      }
    });
  };

const handleSincronizareDosarMeniuOK = async () => {
  setSeIncarcaPagina(true);
    let method;
    let sincronizareAPIURL;
    let mesajAlerta;

// verific daca am un dosar selectat la click dreapta, altfel le iau din selectedRows daca > 1
  //const idUriPtSincronizare = rowId !== null && selectedRows.length <= 1 ? `id_dosar[]=${rowId}` : selectedRows.map(id => `id_dosar[]=${id}`).join('&');
  const idUriPtSincronizare = rowId !== null && selectedRows.length <= 1 ? [rowId] : selectedRows;

  sincronizareAPIURL = `${process.env.REACT_APP_API_URL}monitorizari?sincronizat=1`;
  method = 'POST';
  mesajAlerta = selectedRows.length > 1 ?
      'Dosarele au fost adăugate la lista de sincronizare' :
      'Dosarul a fost adăugat la lista de sincronizare';

  const sincronizareAPIResponse = await fetch(sincronizareAPIURL, {
  method: method,
  headers: {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
  },
  body: JSON.stringify({ ids: idUriPtSincronizare }),  
});

  if (sincronizareAPIResponse.ok) {
    // Construiește mesajul de alertă bazat pe acțiunea efectuată
 
    Swal('Succes', mesajAlerta, 'success');
    fetchData();
    setSeIncarcaPagina(false);
    // Actualizează starea de monitorizat pentru dosarele selectate
     
  }
   else {
    Swal('Atenționare', 'A apărut o eroare la monitorizarea dosarului!', 'warning');
    setSeIncarcaPagina(false);
  }
   
  setRowId(null);  
  setContextMenu(null);

};
const handleSincronizareDosar = useCallback(async (idDosar) => {
  const dosar = dosare.find((dosar) => dosar.id === idDosar);
  if (!dosar) {
    return;
  }

  const sincronizat = dosar.sincronizat;

  const sincronizareAPIURL = `${process.env.REACT_APP_API_URL}monitorizari/${idDosar}?sincronizat=${sincronizat === 1 ? 0 : 1}`;
  const sincronizareAPIResponse = await fetch(sincronizareAPIURL, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });

  if (sincronizareAPIResponse.ok) {
    const mesajAlerta = sincronizat === 1 ? 'Dosarul a fost eliminat din lista de sincronizare' : 'Dosarul a fost adăugat la lista de sincronizare';
    Swal('Succes', mesajAlerta, 'success');
    if (sincronizat === 1) {
      setRowSincronizat(0);
    } else {
      setRowSincronizat(1);
    }
    fetchData();
    const updatedDosare = dosare.map((dosar) => {
      if (dosar.id === idDosar) {
        return {
          ...dosar,
          sincronizat: sincronizat === 1 ? 0 : 1,
        };
      }
      return dosar;
    });
    setDosare(updatedDosare);
  } else {
    Swal('Atenționare', 'A apărut o eroare la sincronizarea dosarului!', 'warning');
  }
}, [dosare, fetchData, token]);

 

const formatDate = (date) => {
  if (!date) return '';
  const d = new Date(date);
  const day = d.getDate().toString().padStart(2, '0');
  const month = (d.getMonth() + 1).toString().padStart(2, '0'); // luna începe de la 0
  const year = d.getFullYear();
  return `${day}.${month}.${year}`;
};  
const sortNumarDosar = (a, b) => { 
  const numarA = parseInt(a.split('/')[0]);
  const numarB = parseInt(b.split('/')[0]);

  if (numarA < numarB) return -1;
  if (numarA > numarB) return 1;
  return 0;
};

const [filterModel, setFilterModel] = useState({
  items: [], 
});

const handleFilterModelChange = (newModel) => {
  const targetColumns = ['datacreare', 'dataum', 'datastadiu', 'datatermen'];

  const newItems = newModel.items.map((item) => {
    if (targetColumns.includes(item.columnField) && (!item.operatorValue || item.operatorValue === 'defaultOperator')) {
      return { ...item, operatorValue: 'equals' };
    }
    return item;
  });

  setFilterModel({ ...newModel, items: newItems });
};


const dateFilterOperators = useMemo(() => [
  {
    label: 'egal cu',
    value: 'contains',
    getApplyFilterFn: (filterItem) => {
      if (!filterItem.value) {
        return null;
      }
      return (params) => {
        const dateFromRow = new Date(params.value).setHours(0, 0, 0, 0);
        const selectedDate = new Date(filterItem.value).setHours(0, 0, 0, 0);
        return dateFromRow === selectedDate;
      };
    },
    InputComponent: DateFilterComponent,
  },
  {
    label: 'înainte de',
    value: 'before',
    getApplyFilterFn: (filterItem) => {
      if (!filterItem.value) {
        return null;
      }
      return (params) => {
        const dateFromRow = new Date(params.value).setHours(0, 0, 0, 0);
        const selectedDate = new Date(filterItem.value).setHours(0, 0, 0, 0);
        return dateFromRow < selectedDate;
      };
    },
    InputComponent: DateFilterComponent,
  },
  {
    label: 'după data',
    value: 'after',
    getApplyFilterFn: (filterItem) => {
      if (!filterItem.value) {
        return null;
      }
      return (params) => {
        const dateFromRow = new Date(params.value).setHours(0, 0, 0, 0);
        const selectedDate = new Date(filterItem.value).setHours(0, 0, 0, 0);
        return dateFromRow > selectedDate;
      };
    },
    InputComponent: DateFilterComponent,
  },
], []);

const favorabilFilterOperators = [
  {
    label: 'Filter by',
    value: 'contains',
    getApplyFilterFn: (filterItem) => {
      if (!filterItem.value || filterItem.value === 'Toate') {
        return null;
      }
      return ({ value }) => {
        if (filterItem.value === 'Fără stare') {
          return value === null || value === undefined || value === '';
        }
        return value === filterItem.value;
      };
    },
    InputComponent: (props) => {
      const { item, applyValue } = props;
      const handleChange = (event) => {
        applyValue({ ...item, value: event.target.value });
      };
      return (
        <Select
          value={item.value || 'Toate'}
          onChange={handleChange}
          size="small"
          fullWidth
        >
          <MenuItem value="Toate">Toate</MenuItem>
          <MenuItem value="Favorabil">Favorabil</MenuItem>
          <MenuItem value="Nefavorabil">Nefavorabil</MenuItem>
          <MenuItem value="Fără stare">Fără stare</MenuItem>
        </Select>
      );
    },
  },
];



//coloane custom
const parseCustomFields = useCallback((customFieldsJson) => {
  try {
    const fields = JSON.parse(customFieldsJson);
    return fields.map(field => {
 
      const baseConfig = {
        field: field.name,
        headerName: field.label,
        width: 200,
        sortable: true,
        filterable: true,
      };
   
      if (field.type === 'date') {
        return {
          ...baseConfig,
          filterOperators: dateFilterOperators, 
          valueFormatter: (params) => {
            return params.value ? new Date(params.value).toLocaleDateString('ro-RO') : '';
          }
        };
      } else {
        return {
          ...baseConfig,
          valueFormatter: (params) => params.value 
        };
      }

    });
  } catch (error) {
    console.error('Error parsing custom fields:', error);
    return [];
  }
}, [dateFilterOperators]);



const getAllCustomColumns = (dosare) => {
  const allFields = new Set();
  dosare.forEach(dosar => {
    if (dosar.custom_fields) {
      const customFields = JSON.parse(dosar.custom_fields);
      customFields.forEach(field => {
        if (field.listing === 1) {  // daca atributul listing este setat la 1 nu afisez campul
          allFields.add(JSON.stringify(field));   
        }
      });
    }
  });
  return Array.from(allFields).map(field => JSON.parse(field));  
};
 
const countDosareConsultanta = (selectedRows) => {
  return selectedRows.filter(row => !row.is_dosar_instanta).length;
};

const getUniqueUsers = (data) => {
  const uniqueUsers = new Set();
  data.forEach((item) => {
    if (item.user_name) {
      uniqueUsers.add(item.user_name);
    }
  });
  return Array.from(uniqueUsers);
};

const utilizatoriFilterOperators = (uniqueUsers) => [
  {
    label: 'Selectare utilizator',
    value: 'contains',
    getApplyFilterFn: (filterItem) => {
      if (!filterItem.value || filterItem.value === 'Toți utilizatorii') {
        return null;
      }
      return ({ value }) => {
        return value === filterItem.value;
      };
    },
    InputComponent: (props) => {
      const { item, applyValue } = props;
      const handleChange = (event, newValue) => {
        applyValue({ ...item, value: newValue });
      };
      return (
        <Autocomplete
          value={item.value || ''}
          onChange={handleChange}
          options={['Toți utilizatorii', ...uniqueUsers]}
          size="small"
          noOptionsText="Nicio opțiune"
          renderInput={(params) => <TextField {...params} label="Selectare utilizator" />}
          className='inputmic campCustomFiltrare'
        />
      );
    },
  },
];

 
// functie pentru a genera autocomplete-uri in capul de filtrare pe coloanele dorite 
const generateFilterOperators = (columnData, label, allOptionLabel) => [
  {
    label: `Selectare ${label}`,
    value: 'contains',
    getApplyFilterFn: (filterItem) => {
      if (!filterItem.value || filterItem.value === allOptionLabel) {
        return null;
      }

      return ({ value }) => {
        if (filterItem.value === `Lipsă ${label}`) {
          return value == null;
        }
        return (
          value != null &&
          removeDiacritics(value.toLowerCase()).includes(removeDiacritics(filterItem.value.toLowerCase()))
        );
      };
    },
    InputComponent: (props) => {
      const { item, applyValue } = props;
      const [inputValue, setInputValue] = useState(item.value || '');

      // Funcția care aplică efectiv filtrul, dar e debounced
      const debouncedApplyValue = useCallback(
        debounce((value) => {
          applyValue({ ...item, value });
        }, 800),
        []
      );

      const handleInputChange = (event, newInputValue) => {
        setInputValue(newInputValue);
        debouncedApplyValue(newInputValue);
      };

      // Transformă valorile null/undefined în "Lipsă {label}"
      const transformedOptions = columnData.map((option) =>
        option == null ? `Lipsă ${label}` : option
      );

      // Adaugă opțiunea "Lipsă {label}" imediat după "Oricare"
      const filteredOptions = [allOptionLabel, `Lipsă ${label}`, ...transformedOptions.filter(option => option !== `Lipsă ${label}`)];

      return (
        <Autocomplete
          freeSolo
          inputValue={inputValue}
          onInputChange={handleInputChange}
          options={filteredOptions}
          size="small"
          noOptionsText="Nicio opțiune"
          renderInput={(params) => <TextField {...params} label={`Selectare ${label}`} />}
          className='inputmic campCustomFiltrare'
        />
      );
    },
  },
];
 
// Combinarea funcțiilor de filtrare generate cu operatorii de text
const combinedFilterOperators = (columnData, label, allOptionLabel) => [
  ...generateFilterOperators(columnData, label, allOptionLabel),
  ...stringFilterOperators
];

const columns: GridColDef[] = React.useMemo(() => {
//const customColumns = dosare.length > 0 && dosare[0].custom_fields ? parseCustomFields(dosare[0].custom_fields) : [];

    const uniqueUsers = getUniqueUsers(dosare); 
    const allCustomFields = getAllCustomColumns(dosare);
    const customColumns = parseCustomFields(JSON.stringify(allCustomFields));
    
    const uniqueCustomColumns = customColumns.filter((column, index, self) =>
      index === self.findIndex((c) => c.field === column.field)
    );
    
    const uniqueInstances = Array.from(new Set(dosare.map((row) => row.deninstanta)));
    const uniqueObiecte = Array.from(new Set(dosare.map((row) => row.denumireobiect)));
    const uniqueStadii = Array.from(new Set(dosare.map((row) => row.denumirestadiu)));
    const uniqueCalitati = Array.from(new Set(dosare.map((row) => row.calitateclientdosar)));
    const uniqueMaterii = Array.from(new Set(dosare.map((row) => row.materie_obiect_dosar)));
    const uniqueStari = Array.from(new Set(dosare.map((row) => row.staredosar)));
    const uniqueSolutii = Array.from(new Set(dosare.map((row) => row.solutiedosar)));
 

    //console.log('customColumns',customColumns); 

  const standardColumns = [
    {
      field: 'numardosarfinal',
      headerName: 'Număr dosar',
      width: 280,
      hideable: false,
      renderHeader: () => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <span style={{ fontWeight: '500' }}>Număr dosar</span>
          <Tooltip title="Opțiuni afișare">
            <IconButton
              onClick={(event) => {
                event.stopPropagation();  
                handleMenuClick(event); 
              }}
              size="small"
              style={{ marginLeft: '5px' }}
            >
              <ArrowDropDownIcon />
            </IconButton>
          </Tooltip>
          <Menu
            anchorEl={anchorElDosare}
            open={Boolean(anchorElDosare)}
            onClose={handleMenuClose}
          >
            <MenuItem
              onClick={handleShowDosareNoi}
              className={showDosareNoi ? 'meniuActive' : ''}
            >
              Afișare doar dosare noi
            </MenuItem>
            <MenuItem
              onClick={handleShowDosareFinalizate}
              className={showDosareFinalizate ? 'meniuActive' : ''}
            >
              Afișare doar dosare finalizate
            </MenuItem>
            <MenuItem
              onClick={handleShowAllDosare}
              className={!showDosareNoi && !showDosareFinalizate ? 'meniuActive' : ''}
            >
              Afișare toate dosarele
            </MenuItem>
          </Menu>
        </div>
      ),
      
      sortComparator: sortNumarDosar,
      renderCell: (params) => (
        <div onMouseEnter={() => handleRowHover(params)} onMouseLeave={() => setHoveredRow(null)}>
          <div className="d-flex align-items-center">
          {params.row.is_dosar_instanta == 1 && ( // eslint-disable-line eqeqeq
              <div  onClick={() => setOpen(true)}>
              {params.value}
              </div>
          )}
          {!params.row.is_dosar_instanta == 1 && ( // eslint-disable-line eqeqeq
              <div onClick={() => setOpen(true)}>
                <Tooltip title={`Dosar de consultanță ${params.row.numarintern}`}>
                  <span><CopyrightIcon style={{fontSize:'12px'}} /> {params.row.numarintern}</span>
                </Tooltip>
              </div>
          )}
            {params.row.is_dosar_instanta == 1 && ( // eslint-disable-line eqeqeq
            <>
            {hoveredRow === params.row.id && (
              <div className="ml-auto"> 
                <Tooltip title="Detalii dosar">
                  <IconButton size="small" onClick={() => setOpen(true)}>
                    <InfoIcon />
                  </IconButton>
                </Tooltip>
              </div>
            )}
            </>
            )}
          </div>
        </div>
        ),  
      filterOperators: stringFilterOperators.map((operator) =>
        wrapFilterOperator(operator)
      )
      },
      { field: 'numarintern', headerName: 'Număr intern', width: 200, filterOperators: stringFilterOperators.map((operator) => wrapFilterOperator(operator)) },
      { field: 'deninstanta', headerName: 'Instanță dosar', width: 200, filterOperators: combinedFilterOperators(uniqueInstances, 'instanță', 'Oricare instanță') },
      { field: 'denumireobiect', headerName: 'Obiect dosar', width: 200, filterOperators: combinedFilterOperators(uniqueObiecte, 'obiect', 'Oricare obiect') },
      //{ field: 'denumireobiect', headerName: 'Obiect dosar', width: 200, filterOperators: stringFilterOperators.map((operator) => wrapFilterOperator(operator)) },
      { field: 'denumirestadiu', headerName: 'Stadiu dosar', width: 200, filterOperators: combinedFilterOperators(uniqueStadii, 'stadiu', 'Oricare stadiu') },
      //{ field: 'denumirestadiu', headerName: 'Stadiu dosar', width: 200, filterOperators: stringFilterOperators.map((operator) => wrapFilterOperator(operator)) },
      { field: 'dosar_groups', headerName: 'Grupuri dosar', width: 200, filterOperators: stringFilterOperators.map((operator) => wrapFilterOperator(operator)) },
      { field: 'client', headerName: 'Client dosar', width: 200 },
      // { field: 'datatermen', headerName: 'Dată termen', width: 200 },
      
      
     // { field: 'titlefinalizat', headerName: 'Stadiu dosar', width: 200 },
     { 
      field: 'dataum', 
      headerName: 'Dată actualizare', 
      width: 250,
      filterable: true,
      filterOperators: dateFilterOperators,
      valueFormatter: ({ value }) => formatDate(value),
    }, 
    {
      field: 'datacreare',
      headerName: 'Data Creare',
      width: 250,
      filterable: true,
      filterOperators: dateFilterOperators,
      valueFormatter: ({ value }) => formatDate(value),
    },

    { 
      field: 'datastadiu', 
      headerName: 'Dată stadiu', 
      width: 250,
      filterable: true,
      filterOperators: dateFilterOperators,
      valueFormatter: ({ value }) => formatDate(value),
    },
    { 
      field: 'datatermen', 
      headerName: 'Dată termen', 
      width: 250,
      filterable: true,
      filterOperators: dateFilterOperators,
      valueFormatter: ({ value }) => formatDate(value),
    },
    
    { field: 'parti_adverse', headerName: 'Părți adverse', width: 200, filterOperators: stringFilterOperators.map((operator) => wrapFilterOperator(operator)) },
    { field: 'parti_dosar', headerName: 'Părți dosar', width: 200, filterOperators: stringFilterOperators.map((operator) => wrapFilterOperator(operator)) },
      // { field: 'adresaclientdosar', headerName: 'Adresă client dosar', width: 200 },
      // { field: 'adresaparteadversa', headerName: 'Adresă parte adversă', width: 200 },
     
      { field: 'calitateclientdosar', headerName: 'Calitate client dosar', width: 200, filterOperators: combinedFilterOperators(uniqueCalitati, 'calitate', 'Oricare calitate') },
      //{ field: 'calitateclientdosar', headerName: 'Calitate client dosar', width: 200, filterOperators: stringFilterOperators.map((operator) => wrapFilterOperator(operator)) },
      //{ field: 'calitateparteadversa', headerName: 'Calitate parte adversă', width: 200, filterOperators: stringFilterOperators.map((operator) => wrapFilterOperator(operator)) },
      { field: 'CUIclient', headerName: 'CUI client dosar', width: 200 },
      { field: 'cui_parti_adverse', headerName: 'CUI părți adverse', width: 200, filterOperators: stringFilterOperators.map((operator) => wrapFilterOperator(operator)) },
      // { field: 'RECOMclientdosar', headerName: 'RECOM client dosar', width: 200 },
      // { field: 'RECOMparteadversa', headerName: 'RECOM parte adversă', width: 200 },
      // { field: 'sentintaprimita', headerName: 'Sentință primită', width: 200 }, 
      // { field: 'nrstadii', headerName: 'Număr stadii', width: 200 },
      
      { field: 'materie_obiect_dosar', headerName: 'Materie dosar', width: 200, filterOperators: combinedFilterOperators(uniqueMaterii, 'materie', 'Oricare materie') },
      //{ field: 'materie_obiect_dosar', headerName: 'Materie dosar', width: 200, filterOperators: stringFilterOperators.map((operator) => wrapFilterOperator(operator)) },
      // { field: 'txtum', headerName: 'Data ultimei actualizări', width: 200 },
      // { field: 'dosarinstanta', headerName: 'Dosar instanță', width: 200 },
      //{ field: 'solutie', headerName: 'Soluție', width: 200 },
      { field: 'descrieresolutie', headerName: 'Descriere soluție', width: 200, filterOperators: stringFilterOperators.map((operator) => wrapFilterOperator(operator)) },
      {
        field: 'favorabil',
        headerName: 'Finalizare dosar',
        width: 200,
        filterOperators: favorabilFilterOperators,
      },
      // { field: 'comentarii', headerName: 'Comentarii', width: 200 },
      //{ field: 'legislatie', headerName: 'Legislație', width: 200, filterOperators: stringFilterOperators.map((operator) => wrapFilterOperator(operator)) },
      
      { field: 'staredosar', headerName: 'Stare dosar', width: 200, filterOperators: combinedFilterOperators(uniqueStari, 'stare', 'Oricare stare') },
      //{ field: 'staredosar', headerName: 'Stare dosar', width: 200, filterOperators: stringFilterOperators.map((operator) => wrapFilterOperator(operator)) },
      { field: 'solutiedosar', headerName: 'Soluție dosar', width: 200, filterOperators: combinedFilterOperators(uniqueSolutii, 'soluție', 'Oricare soluție') },
      //{ field: 'solutiedosar', headerName: 'Soluție dosar', width: 200, filterOperators: stringFilterOperators.map((operator) => wrapFilterOperator(operator)) },
      { 
        field: 'datafinalizare', 
        headerName: 'Dată soluție', 
        width: 250,
        filterable: true,
        filterOperators: dateFilterOperators,
        valueFormatter: ({ value }) => formatDate(value),
      },
      //{ field: 'user_name', headerName: 'Utilizator', width: 200, filterOperators: stringFilterOperators.map((operator) => wrapFilterOperator(operator)) },
      {
        field: 'user_name',
        headerName: 'Utilizatori',
        width: 200,
        filterable: true,
        filterOperators: utilizatoriFilterOperators(uniqueUsers),
      },
      { 
        field: 'dosar_adaugat_la_data', 
        headerName: 'Dosar importat',  
        width: 250,
        filterable: true,
        filterOperators: dateFilterOperators,
        valueFormatter: ({ value }) => formatDate(value),
      },
      { 
        field: 'dosar_modificat_la_data', 
        headerName: 'Dosar editat',  
        width: 250,
        filterable: true,
        filterOperators: dateFilterOperators,
        valueFormatter: ({ value }) => formatDate(value),
      },
      //{ field: 'accesorii', headerName: 'Accesorii', width: 200 },
      //{ field: 'monitorizat', headerName: 'Monitorizat', width: 200 },
      // { field: 'emailuser', headerName: 'Email utilizator', width: 200 },
      // { field: 'denumireuser', headerName: 'Denumire utilizator', width: 200 },
      // { field: 'detaliiuser', headerName: 'Detalii utilizator', width: 200 },
      // { field: 'telefonuser', headerName: 'Telefon utilizator', width: 200 },
    ];
    return [...standardColumns, ...uniqueCustomColumns]; 
  }, [dosare, hoveredRow, stringFilterOperators, wrapFilterOperator, dateFilterOperators, handleMonitorizareDosar, handleSincronizareDosar, parseCustomFields]);

  const handleDeleteSelected = async () => {
    const swal = require('sweetalert');
        
    const shouldDelete = await swal({
      title: "Sunteți sigur?",
      text: "Dosarele șterse se vor regăsi în zona de recuperare dosare șterse!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    });
  
    if (shouldDelete) {
      try {
        // verific daca am un dosar selectat la click dreapta, altfel le iau din selectedRows daca > 1
        const idsToDelete = rowId !== null && selectedRows.length <= 1 ? [rowId] : selectedRows;
        setSeIncarcaPagina(true);
        console.log("rowId:", rowId);
        console.log("selectedRows:", selectedRows);
        console.log("idsToDelete:", idsToDelete);
        //const idsString = idsToDelete.join(',');
  
        const response = await fetch(`${process.env.REACT_APP_API_URL}softDelete`, {
          method: 'DELETE',  
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          },
          body: JSON.stringify({ ids: idsToDelete }),  
        });
  
        if (response.status === 200) {
          const remainingDosare = dosare.filter(dosar => !idsToDelete.includes(dosar.id));
          setDosare(remainingDosare);
            
          //const deleteMessage = idsToDelete.length > 1 ? "Dosarele" : "Dosarul";
          //swal("Succes!", `${deleteMessage} cu ID-urile ${idsToDelete.join(', ')} au fost șterse cu succes.`, "success");
          const deleteMessage = idsToDelete.length > 1 
          ? `Cele ${idsToDelete.length} dosare selectate au fost șterse. Le puteți recupera din Recycle Bin!` 
          : "Dosarul pe care l-ați selectat a fost șters. Îl puteți recupera din Recycle Bin!";
          setSeIncarcaPagina(false);
           swal("Succes!", deleteMessage, "success");
        

          
        } else {
          throw new Error('A apărut o eroare la ștergerea dosarelor selectate.');
        }
  
      } catch (error) {
        console.error(error);
        swal("Eroare!", `A apărut o eroare la ștergerea dosarelor selectate.`, "error");
        setSeIncarcaPagina(false);
      }
      finally {
      setSeIncarcaPagina(false);
    }
      setSelectedRows([]); 
      setRowId(null);  
      setContextMenu(null);
    }
  };
  


  const handleRowClick = (params, event) => {
    // && hoveredRow !== params.row.id //aici activez sau dezactivez click-ul pe coloana numar sa apara modala de info

     if (event.target.type !== 'checkbox') {
      setSelectedRow(params.row);
      event.stopPropagation();
    }
  
    if (event.target.type !== 'checkbox' && hoveredRow !== params.row.id) {
      setSelectedRow(params.row);
      setOpen(true);
    }
     
 
    setRowMonitorizat(params.row.monitorizat);
    setRowSincronizat(params.row.sincronizat);
    setDosarInstantaSingur(params.row.is_dosar_instanta);
    fetchDetaliiPartiDosar(params.row.id); 
    fetchTermeneDosar(params.row.id);  
     
  };
 
  const handleSelectionModelChange = (newSelectionModel, columnVisibility) => {
    setSelectedRows(newSelectionModel);

    const selectedIDs = new Set(newSelectionModel.map(String));
    const selectedRowsData = filteredDosare.filter((row) =>
      selectedIDs.has(String(row.id))
    ).map(row => {
      const visibleData = {};
      for (const key in row) {
        if (columnVisibility[key] !== false) { // daca coloana nu e setata invizibila
          visibleData[key] = row[key];
        }
      }
      return visibleData;
    });
    
    setSelectedRows2(selectedRowsData);
    checkIsDosarInstanta(selectedRowsData);

    console.log("ce e selectat:", newSelectionModel);
    console.log("datele rândurilor selectate:", selectedRowsData);
  };
 
const csvOptions = {
  fileName: fileNameCSV,
  delimiter: ',',
  utf8WithBom: true,
};

const FlexContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
});


const CustomGridToolbarContainer = ({
  accesoriiFiltru,
  setAccesoriiFiltru,
  monitorizatFiltru,
  setMonitorizatFiltru,
  printOptions,  
  
}) => {
  const apiRef = useGridApiContext();

const saveState = () => {
  const state = apiRef.current.exportState();
  localStorage.setItem("stateArhivat", JSON.stringify(state));
  Swal("Succes!", "Starea personalizată a listei de dosare proprii a fost salvată cu succes", "success");
};

const resetState = async () => {
    const shouldProceed = await Swal({
      title: "Confirmare",
      text: `Sunteți sigur că doriți să reveniți la starea inițiață a listei de dosare?`,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    });
    if (shouldProceed) {
      Swal("Succes!", "Lista de dosare proprii a revenit la starea inițială", "success");
      localStorage.removeItem("stateArhivat");
      window.location.reload(); 
    } else {
      
    }
};

 // consturiesc datele pentru export in excel a coloanelor active
/*
 const columnMapping = columns.reduce((acc, column) => {
  acc[column.field] = column.headerName;
  return acc;
}, {});
*/

const prepareDataForExport = (data, columns, columnVisibilityModel) => {
  const columnInfoMap = columns.reduce((acc, column) => {
    if (columnVisibilityModel[column.field] !== false) {  
      acc[column.field] = column.headerName || column.field;
    }
    return acc;
  }, {});

  const orderedColumnFields = columns
    .filter(col => columnVisibilityModel[col.field] !== false)
    .map(col => col.field);

  return data.map(row => {
    const filteredRow = {};
    orderedColumnFields.forEach(key => {
      if (key !== '__check__') {
        filteredRow[columnInfoMap[key]] = row[key];
      }
    });
    return filteredRow;
  });
};
  return (
    <>
    <GridToolbarContainer>
      <FlexContainer  style={{paddingBottom:'10px', paddingTop:'5px'}}>
      <Box sx={{ display: 'flex', alignItems: 'left', mr: 1  }}>
      
      <HelpDialog
          title="SELECTARE COLOANE ȘI FILTRE"
          contentType="video"
          content="https://www.ctce.ro/important/juristpronext/tutoriale/lista-dosare-coloane-filtre.mp4" 
          tooltip="Ajutor: Cum se afișează coloanele și cum se realizează filtrări în lista de dosare?"
        />  
        <GridToolbarColumnsButton /> 
        <GridToolbarFilterButton /> 
        <GridToolbarDensitySelector /> 
      
  <GridToolbarExportContainer >
    <GridPrintExportMenuItem options={printOptions} />
 
    <MenuItem onClick={handleOpenExportDosare}>
       Imprimare detaliată 
     </MenuItem>
     
     <MenuItem
      onClick={handleOpenExportDosareExcel}
      display="flex"
      justifyContent="space-between"
      alignItems="center"
    >
      Export detaliat Excel &nbsp;&nbsp;&nbsp;
      <span
        onClick={(event) => {
          event.stopPropagation();
        }}
      >
        <HelpDialog
          title="EXPORT DETALIAT DOSARE"
          contentType="video"
          content="https://www.ctce.ro/important/juristpronext/tutoriale/lista-dosare-export-detaliat.mp4"
          tooltip="Ajutor: Cum se realizează exportul detaliat al dosarelor?"
        />
      </span>
    </MenuItem>

     <MenuItem onClick={handleExporturiGenerate} className='culoareDefault'>
       Exporturi generate
     </MenuItem>
     <ExportInExcel
        csvData={prepareDataForExport(
          selectedRows2.length > 0 ? selectedRows2 : filteredDosare,
          columns,
          columnVisibilityModel
        )}
        fileName={fileNameExcel}
      />
    <GridCsvExportMenuItem  options={csvOptions}/>
     
    </GridToolbarExportContainer>
    


    {showDeleteButton && (
        <Tooltip 
          title={`${
            showSelectedOnly
              ? 'Afișați toate dosarele din tabel'
              : `Afișați în tabel doar randu${selectedRows.length > 1 ? 'rile dosarele' : 'l selectat'} din listă`
          }`}
        >
          <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
            <Switch
              checked={showSelectedOnly}
              onChange={() => setShowSelectedOnly(!showSelectedOnly)}
              color="primary"
              size='small'
              inputProps={{ 'aria-label': 'switch between showing all and selected rows' }}
            />
            <span 
              onClick={() => setShowSelectedOnly(!showSelectedOnly)}
              style={{
                color: '#1976d2',
                marginRight: '5px',
                textTransform: 'uppercase',
                fontWeight: '500',
                fontSize: '13px',
              }}
            >
              {showSelectedOnly ? 'Toate dosarele' : 'Dosarele selectate'}
            </span>
          </div>
        </Tooltip>
      )}

      </Box>
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant="subtitle2" sx={{ mr: 1 }} style={{fontSize:'13px'}}>
              Accesorii dosare
            </Typography>
            <FormControl variant="outlined" size="small" sx={{ mr: 1 }}>
              <InputLabel id="accesorii-select-label">Toate dosarele</InputLabel>
              <Select
                fullWidth
                size='small'
                style={{ minWidth: '120px' }}
                labelId="accesorii-select-label"
                id="accesorii-select"
                value={accesoriiFiltru}
                onChange={handleAccesoriiChange}
                label="Toate dosarele"  
              >
                <MenuItem value="">Toate dosarele</MenuItem>
                <MenuItem value="1">Cu accesorii</MenuItem>
                <MenuItem value="0">Fără accesorii</MenuItem>
              </Select>
            </FormControl>
          </Box>
          <HelpDialog
            title="SINCRONIZARE ȘI MONITORIZARE DOSARE"
            contentType="video"
            content="https://www.ctce.ro/important/juristpronext/tutoriale/lista-dosare-monitorizare-sincronizare.mp4"
            tooltip="Ajutor: Cum se sincronizează sau monitorizează dosarele?"
           />
 

          <Box sx={{ display: 'flex', alignItems: 'center' }}>

          </Box>
          <Tooltip title="Salvați starea personalizată a listei de dosare proprii">
              <IconButton onClick={saveState} size='small'>
                <GridOnIcon />
              </IconButton>
            </Tooltip>

            <Tooltip title="Resetați starea personalizată a listei de dosare proprii și reveniți la starea inițială">
              <IconButton onClick={resetState} size='small'>
                <GridOffIcon />
              </IconButton>
            </Tooltip>
        </Box>
      </FlexContainer>
    </GridToolbarContainer>
    {isLoading ? (
            <div style={{ width: '100%', marginBottom: 8 }}>
                <LinearProgress /> 
            </div>
        ) : (
             <></>
        )}
    </>
  );
};
  

 
  const apiRef2 = useGridApiRef();
 
  React.useEffect(() => {
    const state = JSON.parse(localStorage.getItem("stateArhivat") || null);
    if (state) {
      apiRef2.current.restoreState(state);
    }
  }, [apiRef2]);

 
const handleOpenDialog = () => {
  setOpenDialog(true);
};

const handleCloseDialog = () => {
  setOpenDialog(false);
  fetchData();
};


 //pentru grupuri dosare
 const [groups, setGroups] = useState([]);
 const groupsMapRef = useRef(new Map());
 const [selectedGroupId, setSelectedGroupId] = useState(null);

 const formatGroups = (rawGroups) => {
   let formattedGroups = [];
   let groupMap = new Map();
   
   rawGroups.forEach(group => {
     group.children = [];
     groupMap.set(group.id, group);
   });
   
   rawGroups.forEach(group => {
     if (group.parent_id !== null) {
       let parent = groupMap.get(group.parent_id);
       if (parent) parent.children.push(group);
     } else {
       formattedGroups.push(group);
     }
   });
   
   return formattedGroups;
 };

 
 const createGroupsMap = useCallback((groups, groupsMap) => {
  groups.forEach(group => {
    groupsMap.set(group.id, group);
    if (group.children.length > 0) {
      createGroupsMap(group.children, groupsMap);
    }
  });
}, []);

 useEffect(() => {
   const fetchGroups = async () => {
     try {
       const response = await fetch(`${process.env.REACT_APP_API_URL}dosarGroups`, {
         headers: {
           'Authorization': `Bearer ${token}`
         },
       });
       
       if (response.ok) {
         const data = await response.json();
         const formattedGroups = formatGroups(data.data.dosarGroups);
         setGroups(formattedGroups);
         createGroupsMap(formattedGroups, groupsMapRef.current);
       } else {
         console.error('Error fetching groups');
       }
     } catch (error) {
       console.error('Error fetching groups', error);
     }
   };
   
   fetchGroups();
  }, [createGroupsMap, token]);
 
 
 useEffect(() => {
   //console.log('groupsMap:', [...groupsMapRef.current]);
 }, [groups]);
 
 const handleSelectGroup = async (selectedGroupId) => {
   const groupId = parseInt(selectedGroupId, 10);
   const selectedGroup = groupsMapRef.current.get(groupId);
   
   if (!selectedGroup) {
     console.error('Grupul selectat nu a fost găsit!', selectedGroupId);
     return;
   }
   
     const dosarIdsToSend = rowId !== null && selectedRows.length <= 1 ? [rowId] : selectedRows;

     try {
      const payload = {
        //parent_id: selectedGroup.parent_id,
        //name: selectedGroup.name,
    };
   
    let url = `${process.env.REACT_APP_API_URL}addDosarToGroup?dosar_group_id=${selectedGroupId}`;
    
    if (selectedGroup.parent_id !== null) {
        url += `&parent_id=${selectedGroup.parent_id}`;
    }

    dosarIdsToSend.forEach(dosarId => {
        url += `&dosar_ids[]=${dosarId}`;
    });
      
       const response = await fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(payload),
    });
      
       if (response.ok) {
         Swal("Succes!", "Dosarele au fost adăugate cu succes în grup.", "success");
         fetchData();
       } else {
         throw new Error('Eroare la adaugare dosare la grup');
       }
     } catch (error) {
       console.error('Eroare la adaugare dosare la grup', error);
       Swal("Eroare!", "Dosarele nu au putut fi adăugate în grup.", "error");
     }
 };
 
 const handleCloseGroupMenu = () => {
  setAnchorEl(null);
};
const handleCloseUserMenu = () => {
  setAnchorEluser(null);
};
const handleCloseUserMenu2 = () => {
  setAnchorEluser2(null);
};

 const handleChangeGroup = async (event) => {
  const selectedGroupId = event.target.value;
  setSelectedGroupId(selectedGroupId); // setez grupul selectat
  const selectedGroup = groupsMapRef.current.get(selectedGroupId);
  if (selectedGroup) {
    const shouldProceed = await Swal({
      title: "Confirmare",
      text: `Sunteți sigur că doriți să adăugați dosarele în ${selectedGroup.name}?`,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    });
    if (shouldProceed) {
      handleSelectGroup(selectedGroupId);  
    } else {
      setSelectedGroupId(null);  
    }
  }
  handleCloseGroupMenu();
};

const populateGroups = (groups, level = 0) => {
  return groups.flatMap(group => [
    <MenuItem 
      className='selectGrupuri' 
      value={group.id} 
      key={group.id} 
      style={{ paddingLeft: `${level * 20}px` }}
      disabled={!group.permite_asociere_dosare}  
    >
      {'—'.repeat(level) + group.name}
    </MenuItem>,
    ...(group.children && group.children.length > 0 ? populateGroups(group.children, level + 1) : [])
  ]);
};
const populateGroupsMenu = (groups, level = 0) => {
  return groups.flatMap(group => [
    <MenuItem 
      className='selectGrupuri' 
      value={group.id} 
      key={group.id} 
      style={{ paddingLeft: `${level * 20}px` }}
      disabled={!group.permite_asociere_dosare}
      onClick={() => handleChangeGroup({ target: { value: group.id } })}
    >
      {'—'.repeat(level) + group.name}
    </MenuItem>,
    ...(group.children && group.children.length > 0 ? populateGroupsMenu(group.children, level + 1) : [])
  ]);
};


const getSelectedRowsToExport = ({
  apiRef,
}: GridPrintGetRowsToExportParams): GridRowId[] => {
  const selectedRowIds = selectedGridRowsSelector(apiRef);
  if (selectedRowIds.size > 0) {
    return Array.from(selectedRowIds.keys());
  }

  return gridFilteredSortedRowIdsSelector(apiRef);
};

const handleReassignUser = async (newUserId) => {
  const dosarIdsToSend = rowId !== null && selectedRows.length <= 1 ? [rowId] : selectedRows;
  const idsString = dosarIdsToSend.join(',');

  try {
    const url = `${process.env.REACT_APP_API_URL}reassignUser/${idsString}?idNewUser=${newUserId}`;

    const response = await fetch(url, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    });

    if (response.ok) {
      Swal("Succes!", "Dosarele au fost mutate cu succes la utilizatorul selectat.", "success");
      setSelectedUserId(null); 
      fetchData();
    } else {
      throw new Error('Eroare la mutarea dosarelor');
    }
  } catch (error) {
    console.error('Eroare la mutarea dosarelor', error);
    Swal("Eroare!", "Dosarele nu au putut fi mutate la utilizatorul selectat.", "error");
  }
  fetchData();
};

const handleChangeUser = async (event) => {
  console.log('event',event);
  const newUserId = event.target.value;

  if (!newUserId) {
    return; 
  }

  setSelectedUserId(newUserId);

  const selectedUser = users.find(user => user.id === Number(newUserId));
  const shouldProceed = await Swal({
    title: "Confirmare",
    text: `Sigur doriți mutarea dosarelor la utilizatorul ${selectedUser.fullname} (${selectedUser.name})?`,
    icon: "warning",
    buttons: true,
    dangerMode: true,
  });

  if (shouldProceed) {
    handleReassignUser(newUserId); 
  }
};
const handleChangeUserMenu = async (newUserId) => {
  handleClose();  
  setSelectedUserId(newUserId);

  const selectedUser = users.find(user => user.id === Number(newUserId));
  const shouldProceed = await Swal({
    title: "Confirmare",
    text: `Sigur doriți mutarea dosarelor la utilizatorul ${selectedUser.fullname} (${selectedUser.name})?`,
    icon: "warning",
    buttons: true,
    dangerMode: true,
  });

  if (shouldProceed) {
    handleReassignUser(newUserId);
  }
};

const filteredDosare = dosare.filter((dosar) => {
  const isInInstanta = showInstanta && dosar.is_dosar_instanta == 1; // eslint-disable-line eqeqeq
  const isConsultanta = showConsultanta && !dosar.is_dosar_instanta == 1; // eslint-disable-line eqeqeq
  
  if (!isInInstanta && !isConsultanta) return false;
  
  if (showDosareNoi) {
    const dosarAddedDate = new Date(dosar.dosar_adaugat_la_data);
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);
    dosarAddedDate.setHours(0, 0, 0, 0);
    if (dosarAddedDate.getTime() !== currentDate.getTime()) return false;
  }
  if (showDosareFinalizate) {
    return dosar.staredosar === 'finalizat';
  }

  const dosarDateStr = dosar[dateFilterType] ? dosar[dateFilterType].split(' ')[0] : null;
  const dosarDate = dosarDateStr ? parseISO(dosarDateStr) : null;
  
  let isAfterStartDate = true;
  let isBeforeEndDate = true;
  
  if (dateFilterType === 'datatermen' && !dosarDate) return false;
  
  if (startDate) {
    const parsedStartDate = startOfDay(parse(startDate, 'dd.MM.yyyy', new Date()));
    const formattedStartDate = format(parsedStartDate, 'yyyy-MM-dd');
    isAfterStartDate = dosarDate >= parseISO(formattedStartDate);
  }
  
  if (endDate) {
    const parsedEndDate = endOfDay(parse(endDate, 'dd.MM.yyyy', new Date()));
    const formattedEndDate = format(parsedEndDate, 'yyyy-MM-dd');
    isBeforeEndDate = dosarDate <= parseISO(formattedEndDate);
  }

  return isAfterStartDate && isBeforeEndDate;
});




      //dialog pt dosare recuperate
      const [isDialogOpenRecuperate, setIsDialogOpenRecuperate] = useState(false);

      const handleOpenDialogRecuperate = () => {
          setIsDialogOpenRecuperate(true);
      };
  
      const handleCloseDialogRecuperate = () => {
          setIsDialogOpenRecuperate(false);
          fetchData();
      };


      const getRowClassName = (params) => {
        let className = `data-id-${params.id} data-is-dosar-instanta-${params.row.is_dosar_instanta ? 1 : 0}`;
      
        // Obținem data curentă fără timp (doar data)
        const currentDate = new Date();
        currentDate.setHours(0, 0, 0, 0); 
      
        // Obținem data din dosar fără timp
        const dosarAddedDate = new Date(params.row.dosar_adaugat_la_data);
        dosarAddedDate.setHours(0, 0, 0, 0);  
      
        // Verificăm dacă datele sunt egale
        const isDosarNou = dosarAddedDate.getTime() === currentDate.getTime();
      
        // Verificăm condițiile de stare și adăugăm clasele corespunzătoare
        if (params.row.staredosar === "finalizat" && params.row.is_dosar_instanta === 1) {
          className += ' rowStareFinalizatInstanta';
        } else if (!(params.row.staredosar === "finalizat") && params.row.is_dosar_instanta === 0) {
          className += ' rowStareInLucruConsultanta';
        } else if ((params.row.accesoriidosar !== "" || params.row.andosar.includes('*')) && params.row.is_dosar_instanta === 1) {
          className += ' rowWithAccesoriiInstanta';
        } else if (params.row.staredosar === "finalizat" && params.row.is_dosar_instanta === 0) {
          className += ' rowStareFinalizatConsultanta';
        } else {
          className += params.indexRelativeToCurrentPage % 2 === 0 ? ' Mui-even' : ' Mui-odd';
        }
      
        // Dacă dosarul a fost adăugat astăzi, adăugăm și clasa 'dosarNou'
        if (isDosarNou) {
          className += ' dosarNou';
        }
      
        return className;
      };
      
      const onRowClickHandler = (params, event) => {
        handleRowClick(params, event);  
        fetchDetaliiPartiDosar(params.row.id);  
        fetchTermeneDosar(params.row.id);  
      };
      function determinaTipulPartii(parte) {
        if (parte.esteClient) {
          return "Client";
        } else if (parte.esteParteAdversa) {
          return "Parte adversă";
        } else {
          return "Parte dosar";
        }
      }
  
   
function formatDateTermen(dateStr) {
  const date = new Date(dateStr);
  const day = date.getDate().toString().padStart(2, '0');  
  const month = (date.getMonth() + 1).toString().padStart(2, '0');   
  const year = date.getFullYear();   
  const hours = date.getHours().toString().padStart(2, '0');   
  const minutes = date.getMinutes().toString().padStart(2, '0');   

  return `${day}.${month}.${year} ora ${hours}:${minutes}`;
}
  const [openDialogTermenSolutie, setOpenDialogTermenSolutie] = useState(false);
  const [sumarSolutie, setSumarSolutie] = useState('');

  const handleOpenDialogTermenSolutie = (sumar) => {
    setSumarSolutie(sumar || "Nu există detalii privind soluția.");
    setOpenDialogTermenSolutie(true);
  };

  const handleCloseDialogTermenSolutie = () => {
    setOpenDialogTermenSolutie(false);
  };


  const [openStareDosarDialog, setOpenStareDosarDialog] = useState(false);

const handleOpenStareDosarDialog = () => {
  setOpenStareDosarDialog(true);
};

const handleCloseStareDosarDialog = () => {
  setOpenStareDosarDialog(false);
  fetchData().then(() => {
    setSelectedRow((prevRow) => {
      const updatedRow = dosare.find(dosar => dosar.id === prevRow.id);
      return { ...updatedRow };
    });
  });
};


useEffect(() => {
  if (selectedRow) {
    const updatedRow = dosare.find(dosar => dosar.id === selectedRow.id);
    if (updatedRow) {
      setSelectedRow({ ...updatedRow });
    }
  }
}, []);



  const [createModalOpen, setCreateModalOpen] = useState(false); 
  const handleCreateModalOpen = (id) => {
    setCreateModalOpen(true);
  };
 
  const handleCreateModalClose = () => {
    setCreateModalOpen(false);
  };
 
  const [anchorElNotif, setAnchorElNotif] = useState(null);

  const handleClickNotif = (event) => {
    setAnchorElNotif(event.currentTarget);
  };

  const handleCloseNotif = () => {
    setAnchorElNotif(null);
  };


  const [showSelectedOnly, setShowSelectedOnly] = useState(false);
  const rowsToDisplay = showSelectedOnly ? selectedRows2 : filteredDosare;
  useEffect(() => {
    if (selectedRows2.length < 1) {
      setShowSelectedOnly(false);
    }
  }, [selectedRows2]);


  
const loadingIndicator = isLoading && selectedRows.length === 0;

  return ( 

      <div 
        style={{ 
          height: '100%', 
          display: 'flex', 
          flexDirection: 'column', 
          maxWidth: isWide ? '98%' : '',   
          transition: 'max-width 0.3s ease-in-out' 
        }} 
        className="container card p-3 mt-3" 
        id='listaDosare'
      >
<style>
  {`
    .rowWithAccesoriiInstanta {
      background: linear-gradient(to right, ${colorDA} 7px, ${colorDA}33 7px);
    }
    .rowStareFinalizatInstanta {
      background: linear-gradient(to right, ${colorDIF} 7px, ${colorDIF}33 7px);
    }
    .rowStareFinalizatConsultanta {
      background: linear-gradient(to right, ${colorDCF} 7px, ${colorDCF}33 7px);
    }
    .rowStareInLucruConsultanta {
      background: linear-gradient(to right, ${colorDCL} 7px, ${colorDCL}33 7px);
    }
    .legenda {
      padding-left: 20px;
      font-size: 13px;
      margin: 3px;
    }

    .dosarNou {position: relative;}
    .dosarNou::before {
      content: "✹"; 
      color: var(--culoare-aplicatie);
      position: absolute;  
      left: 43px; 
      top: 50%; 
      transform: translateY(-50%); 
      padding-right: 10px; 
    }
    .dosarNou2 { 
      color: var(--culoare-aplicatie); 
      margin-left:-17px;
    }

  `}
</style>


<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
  
  <PageExpander onToggle={handleToggle} />

    <IconButton size='small' onClick={() => window.location.href = '/lista-dosare'} >
        <Tooltip 
          title="Revenire la lista de dosare"
        >
          <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}> 
            <ArrowCircleLeftIcon />
          </div>
        </Tooltip>
      </IconButton>
        
  <h5 className='p-2 text-uppercase'>Listă dosare arhivate</h5>
  <div>
  
    <FormControlLabel
      control={<Switch size="small" checked={showInstanta} onChange={(e) => setShowInstanta(e.target.checked)} />}
      label="Instanță"
    />
    <FormControlLabel
      control={<Switch size="small" checked={showConsultanta} onChange={(e) => setShowConsultanta(e.target.checked)} />}
      label="Consultanță"
    />
  </div>
  <InchidereDosare 
    dosare={filteredDosare} 
    open={isInchidereOpen} 
    onClose={() => setIsInchidereOpen(false)} 
  />

</div>
<Box display="flex" justifyContent="space-between"  alignItems="center">
  <FormControl style={{ minWidth: 100 }}>
    <InputLabel id="date-filter-type-label">Tip dată</InputLabel>
    <Select
      className='inputmic'
      labelId="date-filter-type-label"
      id="date-filter-type"
      label="Tip dată"
      value={dateFilterType}
      size="small"
      onChange={(e) => setDateFilterType(e.target.value)}
    >
      <MenuItem value="datacreare">Dată creare</MenuItem>
      <MenuItem value="datatermen">Dată termen dosar</MenuItem>
      <MenuItem value="dataum">Dată actualizare dosar</MenuItem>
    </Select>
  </FormControl> 
 
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ro}>
            <DatePicker
              label="De la"
              name='startDateInput'
              value={startDate ? parse(startDate, 'dd.MM.yyyy', new Date()) : null} 
              onChange={handleDateChangeStart}
              renderInput={(params) => <TextField {...params} size='small' fullWidth style={{width:'100px'}} />}
              inputFormat="dd.MM.yyyy" 
              format="dd.MM.yyyy" 
              mask="__.__.____"
              className='inputMicMic'  
            />
            </LocalizationProvider> 

 
  <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ro}>
            <DatePicker
              label="Până la"
              name='endDateInput'
              value={endDate ? parse(endDate, 'dd.MM.yyyy', new Date()) : null} 
              onChange={handleDateChangeEnd}
              renderInput={(params) => <TextField {...params} size='small' fullWidth />}
              inputFormat="dd.MM.yyyy" 
              format="dd.MM.yyyy" 
              mask="__.__.____"
              className='inputMicMic' 
            />
            </LocalizationProvider> 

<Tooltip title="Resetare interval tip dată pentru afișare dosare în listă">
<IconButton size="small"  onClick={() => {
  setStartDate(null);
  setEndDate(null); 
  setDateFilterType('datacreare');
}}>
  <CancelPresentationIcon />
</IconButton>
</Tooltip>

</Box>

  <div style={{ display: 'flex', gap: '8px' }}>  
 
    {areDrept(DreptDeStergereDosare) ? (
      <>
      <Tooltip title="Recuperare dosare șterse">
                <IconButton onClick={handleOpenDialogRecuperate}>
                <FolderDeleteIcon />
              </IconButton>
            </Tooltip>
            
            <Dialog open={isDialogOpenRecuperate} onClose={handleOpenDialogRecuperate} maxWidth="lg" fullWidth>
                <DialogTitle className='titluModal'><FolderDeleteIcon /> Recuperare dosare șterse</DialogTitle>
                <DialogContent className='mt-3'>
                    <RecuperareDosareSterse />
                </DialogContent>
                <DialogActions>
                    <Button size="small" onClick={handleCloseDialogRecuperate}>Renunțare</Button>
                </DialogActions>
            </Dialog>
      </>
    ) : null }

    <>
    <div>
      <Tooltip title="Afișează legenda culorilor">
        <IconButton aria-describedby={idLegenda} variant="contained" onClick={handleLegenda}>
          <HelpOutlineIcon />
        </IconButton>
      </Tooltip>
      <Popover
        id={idLegenda}
        open={openLegenda}
        anchorEl={legendaEl}
        onClose={handleCloseLegenda}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Typography component="div" sx={{ p: 2 }}>
          <div><p><b>Culori dosare</b></p></div>
          <div className='rowStareFinalizatInstanta legenda'> De instanță finalizate</div>
          <div className='rowWithAccesoriiInstanta legenda'> De instanță cu accesorii</div>
          <div className='rowStareInLucruConsultanta legenda'> Consultanță în lucru</div>
          <div className='rowStareFinalizatConsultanta legenda'> Consultanță finalizate</div>
          <div className='dosarNou2 legenda'> ✹ Dosare importate recent</div>
        </Typography>
      </Popover>
    </div>
    </>

      
      

    {showDeleteButton && (
        <Tooltip title={`Dezarhivare dosar${showDeleteButton2 ? 'e' : ''}`}>
          <IconButton
            aria-controls="user-menu"
            aria-haspopup="true"
            onClick={handleDezArhivareDosarMeniu}  
          > 
            <UnarchiveIcon />
          </IconButton>
        </Tooltip>
      )} 

 
    {areDrept(DreptDeStergereDosare) && showDeleteButton && (
    <Tooltip title={`Ștergere dosar${showDeleteButton2 ? 'e' : ''} din listă`}>
      <Badge badgeContent={selectedRows.length} color="secondary">
        <IconButton onClick={handleDeleteSelected}>
          <DeleteIcon />
        </IconButton>
      </Badge>
    </Tooltip> 
    )}


  </div>
</div>

        {countDosareConsultanta(selectedRows2) > 0 && (
        
        <Alert severity="info" style={{ marginBottom: '10px' }}>
          Aveți {countDosareConsultanta(selectedRows2)} dosar{countDosareConsultanta(selectedRows2) > 1 ? 'e' : ''} de consultanță selectat{countDosareConsultanta(selectedRows2) > 1 ? 'e' : ''}. Monitorizarea și sincronizarea nu sunt disponibile pentru aceste dosare.
        </Alert>
)}
        <DataGridPro  
          apiRef={apiRef2}
          rows={rowsToDisplay}
          //rows={dosare}
          onRowHover={handleRowHover}
          checkboxSelection  
          checkboxSelectionVisibleOnly={false}
          disableSelectionOnClick={true}
          onSelectionModelChange={(ids) => {
            const selectedIDs = new Set(ids);
            const selectedRows = dosare.rows.filter((row) =>
              selectedIDs.has(row.id),
            ); 
            setSelectedRows(selectedRows); 
          }}
          onRowSelectionModelChange  ={handleSelectionModelChange} 
          onFilterModelChange={handleFilterModelChange}
          filterModel={filterModel}
          columns={columns}
          pageSize={15}
          rowsPerPageOptions={[10, 15, 20, 50, 100]}
          pagination
          paginationMode="client"  
          loading={loadingIndicator} 
           /*
          components={{ 
            
            headerFilterMenu: null, 
            Toolbar: () => (
              <>
                {isLoading ? (
                  <div style={{ width: '100%', marginBottom: 8 }}>
                    
                    <CustomGridToolbarContainer
                    accesoriiFiltru={accesoriiFiltru}
                    setAccesoriiFiltru={setAccesoriiFiltru}
                    monitorizatFiltru={monitorizatFiltru}
                    setMonitorizatFiltru={setMonitorizatFiltru}
                  />
                  <LinearProgress /> 
                  </div>
                  
                ) : (
                  <div>
                  <CustomGridToolbarContainer
                    accesoriiFiltru={accesoriiFiltru}
                    setAccesoriiFiltru={setAccesoriiFiltru}
                    monitorizatFiltru={monitorizatFiltru}
                    setMonitorizatFiltru={setMonitorizatFiltru}
                  /> 

                  </div>
                  
                )}
              </>
            ),
          }}
          componentsProps={{
            row: {
              onContextMenu: handleContextMenu,
              style: { cursor: "context-menu" }
            }, 
            toolbar: {  
              csvOptions: {
                fileName: 'nume-fisier',
                delimiter: ',',
                utf8WithBom: true,
              },
            },
          }}
          */ 
          slots={{
            headerFilterMenu: null, 
            toolbar: CustomGridToolbarContainer
          }}
          slotProps={{
            toolbar: {
              accesoriiFiltru,
              setAccesoriiFiltru,
              monitorizatFiltru,
              setMonitorizatFiltru,
              printOptions: { getRowsToExport: getSelectedRowsToExport },
              csvOptions: {
                fileName: 'nume-fisier',
                delimiter: ',',
                utf8WithBom: true,
              },
            },
            row: {
              onContextMenu: handleContextMenu,
              style: { cursor: "context-menu" },
              //'data-is-dosar-instanta': (row) => row.is_dosar_instanta ? 1 : 0,
            }, 
          }}
          //slots={{ toolbar: CustomGridToolbarContainer }}
          //slotProps={{
          //  toolbar: { printOptions: { getRowsToExport: getSelectedRowsToExport } },
          //}}
          localeText={{ 
            ...roRO.components.MuiDataGrid.defaultProps.localeText, 
            headerFilterOperatorContains: 'Filtrare rapidă',
            filterPanelRemoveAll: 'Resetare filtre',
            toolbarExport: 'Export',
            toolbarExportLabel: 'Export',
            toolbarExportCSV: 'În format CSV',
            toolbarExportPrint: 'Imprimare tabel',
            toolbarExportExcel: 'În format Excel',
            filterOperatorContains: 'conține',
            filterOperatorIsAnyOf: 'este una din valorile',
            filterOperatorIsEmpty: 'este necompletat',
            filterOperatorIsNotEmpty: 'este completat',
            headerFilterOperatorDateTimeEquals: 'Egal cu (dată și oră)',
            headerFilterOperatorDateTimeBefore: 'Înainte de (dată și oră)',
            headerFilterOperatorDateTimeAfter: 'După data (dată și oră)',
            
            //filterOperatorEquals: '',
            //filterOperatorStartsWith: '',
            //filterOperatorEndsWith: '',
            //filterOperatorIs: '',
            //filterOperatorNot: '',
            //filterOperatorAfter: '',
            //filterOperatorOnOrAfter: '',
            //filterOperatorBefore: '',
            //filterOperatorOnOrBefore: '',
          }}
        
          density="compact"
          onColumnVisibilityModelChange={(newModel) => setColumnVisibilityModel(newModel)}
          columnVisibilityModel={columnVisibilityModel}
          initialState={{
            pagination: { paginationModel: { pageSize: 15 } },  
          }} 
          onRowClick={onRowClickHandler}
          onCellClick={onRowClickHandler}
          getRowClassName={getRowClassName}
          onRowContextMenu={(params, event) => {
            event.preventDefault();
            handleContextMenu(event, params.row.id, params.row.is_dosar_instanta);
          }}
          unstable_headerFilters 
           
        /> 
      

      {isExportDosareOpen && (
        <ExportDosare 
          open={isExportDosareOpen}
          onClose={handleCloseExportDosare}
          {...(selectedRows2 && selectedRows2.length > 0 ? { dosare: selectedRows2 } : { dosare: filteredDosare })}
        />
      )}
       {isExportDosareExcelOpen && (
        <ExportDosareExcel 
          open={isExportDosareExcelOpen}
          onClose={handleCloseExportDosareExcel}
          {...(selectedRows2 && selectedRows2.length > 0 ? { dosare: selectedRows2 } : { dosare: filteredDosare })}
        />
      )}


{/*Meniuri contextuale*/}
<Menu
  open={contextMenu !== null}
  onClose={handleClose}
  anchorReference="anchorPosition"
  anchorPosition={
    contextMenu !== null
      ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
      : undefined
  }
  componentsProps={{
    root: {
      onContextMenu: (e) => {
        e.preventDefault();
        handleClose();
      }
    }
  }}
>
  {/*Meniu contextual multiple*/} 
  {showDeleteButton2 ? [
    countDosareConsultanta(selectedRows2) > 0 && (
      <Alert severity="info" key="info-alert" style={{ maxWidth: '300px' }}>
      Monitorizarea și sincronizarea nu sunt disponibile pentru dosarele de consultanță. 
      Ați selectat {countDosareConsultanta(selectedRows2)} {countDosareConsultanta(selectedRows2) === 1 ? "dosar de consultanță" : "dosare de consultanță"}.
    </Alert>
    ),
    <MenuItem key="99" onClick={handleCopySelectedRows}>
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <ContentCopyIcon fontSize="small" sx={{ mr: 1 }} />
        <Typography variant="body2">Copiere date</Typography>
      </Box>
      <Typography variant="body2" color="textSecondary">
        Ctrl + C
      </Typography>
    </Box>
  </MenuItem>, 
    
    
  <MenuItem key="112" onClick={handleDezArhivareDosarMeniu}>
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <UnarchiveIcon fontSize="small" sx={{ mr: 1 }} />
        <Typography variant="body2">Dezarhivare dosare</Typography>
      </Box>
      <Typography variant="body2" color="textSecondary">
      
      </Typography>
    </Box>
  </MenuItem>, 

    areDrept(DreptDeStergereDosare) ? ( 
      <MenuItem key="10" onClick={handleDeleteSelected}>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <DeleteIcon fontSize="small" sx={{ mr: 1 }} />
          <Typography variant="body2">Ștergere dosare selectate</Typography>
        </Box>
        <Typography variant="body2" color="textSecondary">
           
        </Typography>
      </Box>
    </MenuItem>
    ) : null,
     
  ] : [
 
     <MenuItem key="99" onClick={handleCopySelectedRows}>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <ContentCopyIcon fontSize="small" sx={{ mr: 1 }} />
          <Typography variant="body2">Copiere date</Typography>
        </Box>
        <Typography variant="body2" color="textSecondary">
           Ctrl + C
        </Typography>
      </Box>
    </MenuItem>,  
    <MenuItem key="6" onClick={() => handleOpenModalIstoric(rowId)}>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <HistoryEduIcon fontSize="small" sx={{ mr: 1 }} />
          <Typography variant="body2">Istoric dosar</Typography>
        </Box>
        <Typography variant="body2" color="textSecondary">
        
        </Typography>
      </Box>
    </MenuItem>, 
     
      
  <MenuItem key="12" onClick={() => handleDezArhivareDosarMeniu2(rowId)}>
  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <UnarchiveIcon fontSize="small" sx={{ mr: 1 }} />
      <Typography variant="body2">Dezarhivare dosar</Typography>
    </Box>
    <Typography variant="body2" color="textSecondary">
    
    </Typography>
  </Box>
</MenuItem>, 

  areDrept(DreptDeStergereDosare) ? ( 
    <MenuItem key="11" onClick={handleDeleteSelected}>
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <DeleteIcon fontSize="small" sx={{ mr: 1 }} />
        <Typography variant="body2">Ștergere dosar</Typography>
      </Box>
      <Typography variant="body2" color="textSecondary">
      
      </Typography>
    </Box>
  </MenuItem>
  ) : null 
      
  ]}
</Menu>


    <Dialog
    open={open}
    onClose={() => setOpen(false)}
    aria-labelledby="row-details-dialog"
    maxWidth="md"
    fullWidth
    >
        <DialogTitle className='titluModal' id="row-details-dialog">
      {selectedRow && (
        <>
         <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          sx={{ marginBottom: 2 }}  
        >
              {!selectedRow.is_dosar_instanta == 1  && ( // eslint-disable-line eqeqeq
              <>Informații dosar consultanță: {selectedRow.numarintern}</>
              )}
              {selectedRow.is_dosar_instanta == 1  && ( // eslint-disable-line eqeqeq
              <>Informații dosar: {selectedRow.numardosarfinal}</>
              )}
              <HelpDialog
                  title="PEVIZUALIZARE ȘI EDITARE DOSAR"
                  contentType="video"
                  content="https://www.ctce.ro/important/juristpronext/tutoriale/lista-dosare-preview-creare.mp4" 
                  tooltip="Ajutor: Cum se previzualizează și editează un dosar?"
                />
        </Box> 
        <div className='butoaneModalaInfo' style={{textAlign:'center'}}> 
        
        Dosar arhivat
          
        
        
          </div>
        </>
      )}
    </DialogTitle>
    <DialogContent className='mt-3'>
      {selectedRow && (
        <div>
         
          <table className='table table-responsive table-striped table-condensed'>
              
              <tbody>
                
              <tr><td className='col-4'>Client: </td><td>{selectedRow.client}   {selectedRow.calitateclientdosar && ` (${selectedRow.calitateclientdosar})`}</td></tr>
              <tr><td className='col-4'>Număr dosar:</td><td>{selectedRow.numardosarfinal}</td></tr>
            <tr>
              <td className='col-4'>Data ultimei modificări:</td>
              <td>
                {selectedRow.dataum ? 
                  new Date(selectedRow.dataum).toLocaleDateString('ro-RO') 
                  : 
                  '-'}
              </td>
            </tr>
            <tr>
              <td className='col-4'>Materie dosar:</td><td>{selectedRow.materie_obiect_dosar}</td></tr>
              <tr><td className='col-4'>Obiect dosar:</td><td>{selectedRow.denumireobiect}</td></tr>
              <tr><td className='col-4'>Stadiu dosar:</td><td>{selectedRow.denumirestadiu}</td></tr>
              <tr>
              <td className='col-4'>Dată stadiu:</td>
              <td>
                {selectedRow.datastadiu ? 
                  new Date(selectedRow.datastadiu).toLocaleDateString('ro-RO') 
                  : 
                  '-'}
              </td>
            </tr>
              <tr><td className='col-4'>Instanță dosar:</td><td>{selectedRow.deninstanta}</td></tr>
              <tr>
                <td className='col-4'>Dată termen:</td>
                <td>
                  {selectedRow.datatermen ? 
                    `${new Date(selectedRow.datatermen).toLocaleDateString('ro-RO')} ora ${new Date(selectedRow.datatermen).toLocaleTimeString('ro-RO', { hour: '2-digit', minute: '2-digit' })}` 
                    : 
                    '-'}
                </td>
              </tr>
              
              <tr><td className='col-4'>Observații:</td><td>{selectedRow.comentarii}</td></tr> 
              <tr><td className='col-4'>Stare dosar:</td><td>{selectedRow.staredosar}</td></tr> 
              {selectedRow.favorabil !== null && (
                <tr>
                  <td className='col-4'>Dosarul a fost finalizat:</td>
                  <td>
                    {selectedRow.favorabil}
                  </td>
                </tr>
              )}


              <tr><td className='col-4'>Jurist:</td><td>{selectedRow.user_fullname}</td></tr> 
                </tbody>
              
          </table>
            
          {
            loadingPartiDosar ? (
              <div>Se încarcă părțile...</div>  
            ) : partiDosar && partiDosar.length > 0 ? (  
              <>
                <b>Părți dosar</b>
                <hr />
                <table className='table table-responsive table-striped table-condensed'>
                  <thead>
                    <tr>
                      <th>Nume</th>
                      <th>Calitate parte</th>
                      <th>Tip parte</th>
                    </tr>
                  </thead>
                  <tbody>
                  {partiDosar.map((parte) => (
                    <tr key={parte.id}>
                      <td className='col-6'>{parte.denumire}</td>
                      <td>{parte.numecalitate}</td>
                      <td>{determinaTipulPartii(parte)}</td> 
                    </tr>
                  ))}
                  </tbody>
                </table>
              </>
            ) : null 
          }

        {
            loadingTermeneDosar ? (
              <div>Se încarcă termenele dosarului...</div>  
            ) : termeneDosar && termeneDosar.length > 0 ? (  
              <>
                <b>Termene dosar</b>
                <hr />
                <table className='table table-responsive table-striped table-condensed'>
                  <thead>
                    <tr>
                      <th>Dată termen</th>
                      <th>Soluția</th>
                      <th>Detalii soluție</th>
                      <th>Stadiu</th>
                    </tr>
                  </thead>
                  <tbody>
                  {termeneDosar.map((termen) => (
                    <tr key={termen.id}>
                      <td>{formatDateTermen(termen.dataTermen)}</td>
                      <td>{termen.tipsolutie}</td> 
                      <td className='text-center'>
                        {termen.sumarsolutie ? (
                          <Button onClick={() => handleOpenDialogTermenSolutie(termen.sumarsolutie)}>Detalii soluție</Button>
                        ) : (
                          <small>Nu există detalii</small>
                        )}
                      </td>
                     <td>{termen.denumirestadiu}</td>
                </tr>
              ))}
            </tbody>
          </table>

          <Dialog open={openDialogTermenSolutie} onClose={handleCloseDialogTermenSolutie}>
            <DialogTitle className='titluModal'>Sumar soluție</DialogTitle>
            <DialogContent className='mt-3'>
              {sumarSolutie.length > 0 ? (
                <p>{sumarSolutie}</p>
              ) : (
                <Alert severity="warning">Nu există detalii privind soluția.</Alert>
              )}
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseDialogTermenSolutie}>Închidere</Button>
            </DialogActions>
          </Dialog>
              </>
            ) : null 
          }

         
          
          
            {/*<details className='smooth-collapse'>
              <summary>Detalii neafișate</summary>
              <div className='content'>
                {selectedRow.titlefinalizat && <p>Stadiu dosar: {selectedRow.titlefinalizat}</p>}
                {selectedRow.dataactualizare && <p>Dată actualizare: {selectedRow.dataactualizare}</p>}
                {selectedRow.adresaclientdosar && <p>Adresă client dosar: {selectedRow.adresaclientdosar}</p>}
                {selectedRow.adresaparteadversa && <p>Adresă parte adversă: {selectedRow.adresaparteadversa}</p>}
                {selectedRow.CUIclientdosar && <p>CUI client dosar: {selectedRow.CUIclientdosar}</p>}
                {selectedRow.CUIparteadversa && <p>CUI parte adversă: {selectedRow.CUIparteadversa}</p>}
                {selectedRow.RECOMclientdosar && <p>RECOM client dosar: {selectedRow.RECOMclientdosar}</p>}
                {selectedRow.RECOMparteadversa && <p>RECOM parte adversă: {selectedRow.RECOMparteadversa}</p>}
                {selectedRow.sentintaprimita && <p>Sentință primită: {selectedRow.sentintaprimita}</p>}
                {selectedRow.nrstadii && <p>Număr stadii: {selectedRow.nrstadii}</p>}
                {selectedRow.dosarinstanta && <p>Dosar instanță: {selectedRow.dosarinstanta}</p>}
                {selectedRow.solutie && <p>Soluție: {selectedRow.solutie}</p>}
 
                {selectedRow.staredosar && <p>Stare dosar: {selectedRow.staredosar}</p>}
                {selectedRow.emailuser && <p>Email utilizator: {selectedRow.emailuser}</p>}
                {selectedRow.denumireuser && <p>Denumire utilizator: {selectedRow.denumireuser}</p>}
                {selectedRow.detaliiuser && <p>Detalii utilizator: {selectedRow.detaliiuser}</p>}
                {selectedRow.telefonuser && <p>Telefon utilizator: {selectedRow.telefonuser}</p>}
              </div>
            </details>*/}



           
        </div>
      )}
    </DialogContent>
    <DialogActions>
      <Button onClick={() => setOpen(false)} color="primary">
        Închidere detalii
      </Button>
    </DialogActions>
    </Dialog>


    <Dialog 
      open={openModal} 
      onClose={handleCloseModal}
      aria-labelledby="row-details-dialog"
      maxWidth={isNotificareAdmin ? 'sm' : 'xs'}
      fullWidth
    >

    <DialogTitle className='titluModal'>
        <Typography variant="h6">Creare notificare dosar {isNotificareAdmin ? 'utilizatori' : ''}</Typography>
        <IconButton sx={{ position: 'absolute', top: 10, right: 10, color:'#FFFFFF' }} onClick={handleCloseModal}>
      <CloseIcon />
    </IconButton>
    </DialogTitle>
    <DialogContent className="createNotificare">
          {isNotificareAdmin && (
            <Box sx={{ mt: 2 }}>
              <Autocomplete
                multiple
                id="user-select"
                options={[...utilizatori, { id: 'all', name: 'Toți utilizatorii', fullname: 'Toți utilizatorii' }]}
                groupBy={(option) => option.fullname.charAt(0).toUpperCase()}
                getOptionLabel={(option) => `${option.fullname} (${option.name})`}
                value={selectedUsers}
                onChange={(_, newValue) => {
                  if (newValue.some((option) => option.id === 'all')) {
                    setSelectedUsers(utilizatori);  
                  } else {
                    setSelectedUsers(newValue.filter((option) => option.id !== 'all'));
                  }
                }}
                disableCloseOnSelect 
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Selectare Utilizatori"
                    placeholder="Caută utilizatori..."
                    value={params.inputProps.value || ''} 
                  />
                )}
              />
            </Box>
          )}

          <Box sx={{ mt: 2 }}>
          <TextField
            id="descriere-notificare"
            name="descriere"
            label="Descriere"
            value={formData.descriere || ''}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
            error={formData.descriere === ''}
            helperText={formData.descriere === '' ? 'Introduceți o descriere pentru notificare' : ''}
          />
          </Box>
          <Box sx={{ mt: 2 }}> 
            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ro}>
            <DatePicker
              label="Dată"
              value={formData.panaladata}  
              onChange={(newValue) => {
                setFormData({ ...formData, panaladata: newValue });
              }}
              renderInput={(params) => <TextField {...params} fullWidth />}
              inputFormat="dd.MM.yyyy" 
              format="dd.MM.yyyy" 
              mask="__.__.____"
              name="panaladata" 
              className='maxWidth mt-2' 
            />
            </LocalizationProvider> 
          </Box>
          <Box sx={{ mt: 2, display: 'flex', alignItems: 'center', gap: 2 }}>
           
          <Box>
          <Typography variant="subtitle2">Notificare</Typography>
            <Switch
              id="notificare-notificare"
              name="notificare"
              checked={formData.notificare === 1}
              onChange={(e) => handleInputChange({ target: { name: 'notificare', value: e.target.checked ? 1 : 0 } })}
              color="primary"
            /> 
            </Box>
            <Box>
            <Typography variant="subtitle2">SMS</Typography>
            <Switch
              id="notificare-sms"
              name="via_sms"
              checked={formData.via_sms === 1}
              onChange={(e) => handleInputChange({ target: { name: 'via_sms', value: e.target.checked ? 1 : 0 } })}
              color="primary"
              disabled={formData.notificare !== 1}
            />
            </Box>
            <Box>
            <Typography variant="subtitle2">E-Mail</Typography>
            <Switch
              id="notificare-email"
              name="via_email"
              checked={formData.via_email === 1}
              onChange={(e) => handleInputChange({ target: { name: 'via_email', value: e.target.checked ? 1 : 0 } })}
              color="primary"
              disabled={formData.notificare !== 1}
            />
            </Box>
            <Box>
            <Typography variant="subtitle2">Push</Typography>
            <Switch
              id="notificare-push"
              name="via_push"
              checked={formData.via_push === 1}
              onChange={(e) => handleInputChange({ target: { name: 'via_push', value: e.target.checked ? 1 : 0 } })}
              color="primary"
              disabled={formData.notificare !== 1}
            />
            </Box> 
          </Box>
          <Box sx={{ mt: 2 }}>
            <Typography variant="subtitle2">Reamintire cu mai multe zile înainte</Typography>
            <Select name="reminder" value={formData.reminder} onChange={handleInputChange} fullWidth margin="normal">
              <MenuItem value={1}>1 zi</MenuItem>
              <MenuItem value={3}>3 zile</MenuItem>
              <MenuItem value={7}>7 zile</MenuItem>
              <MenuItem value={14}>14 zile</MenuItem>
              <MenuItem value={21}>21 zile</MenuItem>
              <MenuItem value={30}>30 zile</MenuItem>
            </Select>
          </Box>
          <Box sx={{ mt: 2 }}>
            <Typography variant="subtitle2">Reamintire zilnică</Typography>
            <Switch
              id="recurent-notificare"
              name="recurent"
              checked={formData.recurent === 1}
              onChange={(e) => handleInputChange({ target: { name: 'recurent', value: e.target.checked ? 1 : 0 } })}
              color="primary"
            /> 
            </Box>
          
        </DialogContent>
        <DialogActions>
             <Button className='mb-2' variant="contained" fullWidth color="primary" onClick={() => handleCreateNotificare('dosar', selectedRowId)}>
              Creare notificare nouă
            </Button>
      </DialogActions>
</Dialog>

    {openModalIstoric && <ModalComponentIstoric open={openModalIstoric} onClose={() => setOpenModalIstoric(false)} idDosar={idDosarIstoric} />}

    {/*<pre style={{ fontSize: 10 }}>
        {JSON.stringify(selectedRows, null, 4)}
      </pre>*/}
    <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      />
    <div>
      {seIncarcaPagina && (
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={true} 
          onClick={() => {}}  
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
    </div>

    </div> 

  );
}

export default ListaDosareArhivate;