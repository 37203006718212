import React, { useState, useEffect } from 'react';
import { TextField, FormHelperText, Tooltip, Button, MenuItem, Typography, Select, FormControl, InputLabel, Box, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Checkbox, FormControlLabel } from '@mui/material';
import { useParams } from 'react-router-dom';
import Swal from 'sweetalert';
import verificareToken from '../VerificareToken';
import DeleteIcon from '@mui/icons-material/Delete';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import InfoIcon from '@mui/icons-material/Info';
import DrepturiUtilizator from '../utilizator/drepturiUtilizator'; 

function PartiDosar() {
  const { id } = useParams();

  const { areDrept } = DrepturiUtilizator(); 
  const DreptDeModificareDosare = 'DreptDeModificareDosare';
  const [isFormValid, setIsFormValid] = useState(false);
  const [selectedParte, setSelectedParte] = useState(null);
  const { token } = verificareToken();


  const [parteaDeEditat, setParteaDeEditat] = useState(null);
  const [partidosar, setPartidosar] = useState([]);
  const [loading, setLoading] = useState(true);
  const [parteDosar, setParteDosar] = useState(false);
  const [open, setOpen] = useState(false);
  const [openView, setOpenView] = useState(false);
  const [loadingParti, setLoadingParti] = useState(false);
  const [calitati, setCalitati] = useState([]);
  const [isExecuting, setIsExecuting] = useState(false);
  const [idStadiuDosar, setIdStadiuDosar] = useState(null);  

  const handleOpen = () => {
    setFormData({ 
      ...initialFormData, 
      estePersoanaJuridica: false
    });  
    setParteaDeEditat(null); 
    setEstePersoanaJuridica(false);
    setOpen(true);
  };
  const handleEditModal = () => { 
    setOpen(true);
  };
  const handleClose = () => setOpen(false);
  const handleCloseView = () => setOpenView(false);
  const [dropdownOptions, setDropdownOptions] = useState([]);

  const [estePersoanaJuridica, setEstePersoanaJuridica] = useState(false);

  const initialFormData = {
    esteClient: false, 
    esteParteAdversa: false,
    estePersoanaJuridica: false,
    numeprenume: "",
    societate: "",
    cui: "",
    registrucomert: "",
    iban: "",
    banca: "",
    adresa: "",
    telefon: "",
    email: "",
    idCalitate: "",
    cnp: ""
  };
  

  const [formData, setFormData] = useState(initialFormData);

    //validari pe adaugare / editare
    const [errors, setErrors] = useState({}); 
    const validateForm = () => {
      let tempErrors = {};
      tempErrors.numeprenume = (formData.numeprenume.length < 3) ? "Numele trebuie să conțină cel puțin 3 caractere." : "";
      tempErrors.societate = estePersoanaJuridica && (!formData.societate || formData.societate.length < 3) ? "Denumirea societății trebuie să conțină cel puțin 3 caractere." : "";
      tempErrors.cui = estePersoanaJuridica && (!formData.cui || formData.cui.length < 5) ? "Completați un CUI valid." : "";
      tempErrors.registrucomert = estePersoanaJuridica && formData.registrucomert ? (formData.registrucomert.length >= 3 ? "" : "Numărul de înregistrare la Registrul Comerțului trebuie să conțină cel puțin 3 caractere.") : "";
      tempErrors.banca = estePersoanaJuridica && formData.banca ? (formData.banca.length >= 3 ? "" : "Numele băncii trebuie să conțină cel puțin 3 caractere.") : "";
      tempErrors.adresa = formData.adresa ? (formData.adresa.length >= 3 ? "" : "Adresa trebuie să conțină cel puțin 3 caractere.") : "";
      tempErrors.telefon = formData.telefon ? (/^(\+?[0-9]{1,3})?[0-9]{6,10}$/.test(formData.telefon) ? "" : "Număr de telefon invalid.") : "";
      tempErrors.email = formData.email ? (/\S+@\S+\.\S+/.test(formData.email) ? "" : "Email invalid.") : "";
      tempErrors.cnp = !estePersoanaJuridica && formData.cnp ? (/^[1-9][0-9]{12}$/.test(formData.cnp) ? "" : "CNP invalid. Trebuie să conțină 13 cifre.") : "";
      tempErrors.idCalitate = !formData.idCalitate ? "Calitatea este obligatorie." : "";  

      setErrors(tempErrors);
    
      const isValid = Object.values(tempErrors).every(x => x === "");
      return isValid;
    };
    
    
  
    const handleSubmitModified = () => {
      const formIsValid = validateForm(); 
      if (formIsValid) {
        handleSubmit();
      }
    };
    
    useEffect(() => {
      const validateAndSetFormValidity = () => {
        const isValid = validateForm();
        setIsFormValid(isValid);
      };
    
      validateAndSetFormValidity();
    }, [formData]);
    
    
/*
  const handleSwitchChange = (event) => {
    setEstePersoanaJuridica(event.target.checked);
    setFormData({
      ...initialFormData,
      estePersoanaJuridica: event.target.checked
    });
  };
  */ 
  const handleSwitchChange = (event) => {
    const isChecked = event.target.checked;
    setEstePersoanaJuridica(isChecked);
  
    if (isChecked) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        societate: prevFormData.numeprenume,
        numeprenume: '',
        cnp: '',
        estePersoanaJuridica: true
      }));
    } else {
      setFormData((prevFormData) => ({
        ...initialFormData,
        numeprenume: prevFormData.societate,
        adresa: prevFormData.adresa,
        telefon: prevFormData.telefon,
        email: prevFormData.email,
        esteClient: prevFormData.esteClient,
        esteParteAdversa: prevFormData.esteParteAdversa,
        idCalitate: prevFormData.idCalitate,
        cnp: '',
        estePersoanaJuridica: false
      }));
    }
  };
  
  
  const formatData = (dataString) => {
    const date = new Date(dataString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}.${month}.${year}`;
  };
 
  const handleDropdownChange = (event) => {
    setIdStadiuDosar(event.target.value);
  };

 
  const fetchDropdownOptions = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}infoStadiiDosar/${id}`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
      const data = await response.json();
  
      if (data.status === 200 && data.data.stadiiDosar.length > 0) {
        // sortez dupa data
        const sortedOptions = data.data.stadiiDosar.sort((a, b) => {
          const dateA = new Date(a.data);
          const dateB = new Date(b.data);
          return dateB - dateA;
        });
   
        const lastStadiu = sortedOptions[0];
  
        setIdStadiuDosar(lastStadiu.id);
        setDropdownOptions(sortedOptions);
        fetchData();
      } else {
        console.error('No stadiiDosar found or error fetching data.');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };
  


  useEffect(() => {
    fetchDropdownOptions();
  }, [id, token]);
 


    // functie pt salvarea unei parti noi
    const handleSubmit = async () => {
      try {
        let data = {
          idStadiuDosar: idStadiuDosar,
          idCalitate: formData.idCalitate,
          esteClient: formData.esteClient,
          esteParteAdversa: formData.esteParteAdversa,
          estePJ: estePersoanaJuridica ? 1 : 0, 
        };

        if (formData.estePersoanaJuridica) {
            data = {
              ...data,
              numeprenume: formData.numeprenume,
              societate: formData.societate,
              cui: formData.cui,
              registrucomert: formData.registrucomert,
              iban: formData.iban,
              banca: formData.banca,
              adresa: formData.adresa,
              telefon: formData.telefon,
              email: formData.email
            };
        } else {
            data = {
              ...data,
              numeprenume: formData.numeprenume,
              societate: '',
              cnp: formData.cnp,
              adresa: formData.adresa,
              telefon: formData.telefon,
              email: formData.email
            };
        }
    
        console.log('Date trimise:', data);
    
        const response = await fetch(`${process.env.REACT_APP_API_URL}parti`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(data)
        });
    
        const responseData = await response.json();
    
        console.log('Răspuns de la API:', responseData);
    
        if (response.status === 201) {
          Swal('Success', 'Partea a fost adaugată cu succes.', 'success');
          handleClose();
          fetchData();
        } else if (response.status === 422 && responseData.message) {
          const errorMessages = Array.isArray(responseData.message) 
            ? responseData.message.join('\n') 
            : responseData.message;
    
          Swal('Atenționare', errorMessages, 'warning');
        } else {
          console.error('Error adding part');
          Swal('Atenționare', 'A apărut o eroare la adăugarea părții.', 'warning');
      }
    } catch (error) {
        console.error('Error:', error);
        Swal('Atenționare', 'A apărut o eroare la adăugarea părții.', 'warning');
    }
    };
    

    const handleDelete = async (idParte, idStadiuDosar) => {
      const confirmDelete = await Swal({
        title: 'Confirmare',
        text: 'Sigur doriți să ștergeți partea?',
        icon: 'warning',
        buttons: {
          cancel: 'Anulare',
          confirm: 'Confirmare',
        },
      });
    
      if (confirmDelete) {
        try {
          //const idStadiuDosar2 = await fetchIdStadiuDosar(); // iau din api parti - idStadiuDosar
          const apiUrl = `${process.env.REACT_APP_API_URL}parti/${idParte}?idStadiuDosar=${idStadiuDosar}`;
          console.log("URL ștergere:", apiUrl);
    
          const response = await fetch(apiUrl, {
            method: 'DELETE',
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
    
          if (response.status === 200) {
            Swal('Confirmare', 'Partea a fost ștearsă cu succes.', 'success');
            fetchData();
          } else {
            console.error('Error deleting part');
            Swal('Atenționare', 'A apărut o eroare la ștergerea părții.', 'warning');
          }
        } catch (error) {
          console.error('Error:', error);
          Swal('Atenționare', 'A apărut o eroare la ștergerea părții.', 'warning');
        }
      }
    };
    
        
    const fetchData = async () => {
      try {
        setLoading(true);
    
        // Încărcăm calitățile o singură dată
        const url = idStadiuDosar ? `${process.env.REACT_APP_API_URL}calitati/${idStadiuDosar}` : `${process.env.REACT_APP_API_URL}calitati`;

        const responseCalitati = await fetch(url, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
    
        const dataCalitati = await responseCalitati.json();
        if (dataCalitati.status !== 200) {
          console.error('Error fetching calitati');
          return;
        }
    
        // Salvăm calitățile
        setCalitati(dataCalitati.data.calitati);
    
        // Încărcăm părțile dosarului
        if (idStadiuDosar) {
        setLoadingParti(true);
        const apiUrl = `${process.env.REACT_APP_API_URL}infoPartiDosar/${idStadiuDosar}`;
        const responseParti = await fetch(apiUrl, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
    
        const dataParti = await responseParti.json();
        if (dataParti.status === 200) {
          // Adăugăm calitățile la fiecare parte din dosar
          const partidosarWithCalitati = dataParti.data.partidosar.map(parte => ({
            ...parte,
            calitati: dataCalitati.data.calitati,
          }));
    
          setPartidosar(partidosarWithCalitati);
          setLoadingParti(false);
        } else {
          console.error(`Eroare încărcare parti dosar: ${dataParti.message}`);
          setLoadingParti(false);
        }
      }
      } catch (error) {
        console.error('Error:', error);
      } finally {
        setLoading(false);
      }
    };
    

    useEffect(() => {
      fetchData();
    }, [id, idStadiuDosar]);

const handleCalitateChange = async (event, parte) => {
    const newCalitate = event.target.value;
    console.log('Calitate noua:', newCalitate);
    const oldCalitate = parte.idcalitate;
    const oldCalitateDenumire = parte.calitati.find((calitate) => calitate.id === oldCalitate)?.denumire;
    const newCalitateDenumire = parte.calitati.find((calitate) => calitate.id === newCalitate)?.denumire;

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}updateParte/${parte.idparte}?idStadiuDosar=${idStadiuDosar}&idCalitate=${newCalitate}`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        const updatedPartidosar = partidosar.map((p) => 
        (p.idparte === parte.idparte ? { ...p, idcalitate: newCalitate } : p));
      

        setPartidosar(updatedPartidosar);
        Swal('', `Calitatea părții ${parte.denumire} a fost schimbată din ${oldCalitateDenumire} în ${newCalitateDenumire}`, 'success');
      } else {
        console.error('Error updating calitate');
        Swal('Atenționare', 'A apărut o eroare la actualizarea calității.', 'warning');
      }
    } catch (error) {
      console.error('Error:', error);
      Swal('Atenționare', 'A apărut o eroare la actualizarea calității.', 'warning');
    }
  };

  const handleInputChange = async (e, parte) => {
    const target = e.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    if (parte) {
      let actualizari = [];
  
      if (name === 'esteClient' && value === true) {
        const clientVechi = partidosar.find(p => p.esteClient && p.idparte !== parte.idparte);
        const updatedPartidosar = partidosar.map(p => {
          if (p.idparte === parte.idparte) {
            return { ...p, esteClient: true, esteParteAdversa: false };
          } else {
            // Dacă este clientul vechi, actualizăm pentru a deveni parte adversă
            // Altfel, menținem statutul părților neschimbat
            return p.esteClient ? { ...p, esteClient: false, esteParteAdversa: true } : p;
          }
        });

        setPartidosar(updatedPartidosar);
        if (clientVechi) {
          actualizari.push(updateParte({ ...clientVechi, esteClient: false, esteParteAdversa: true }));
        }
        actualizari.push(updateParte({ ...parte, esteClient: true, esteParteAdversa: false }));
      } else if (name === 'esteParteAdversa') {
        const updatedPartidosar = partidosar.map(p => 
          p.idparte === parte.idparte ? { ...p, esteParteAdversa: value, esteClient: value ? p.esteClient : false } : p
        );

        setPartidosar(updatedPartidosar);
        actualizari.push(updateParte({ ...parte, esteParteAdversa: value, esteClient: parte.esteClient && !value ? false : parte.esteClient }));
      }

      try {
        await Promise.all(actualizari);
      } catch (error) {
        console.error('Eroare la actualizarea părților:', error);
        Swal('Atenționare', 'A apărut o eroare la actualizarea statutului părților.', 'warning');
      }
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
};



  const updateParte = async (parte) => {
    const newCalitate = parte.idcalitate;

    // Actualizăm starea în API
    try {
      const data = {
        esteClient: parte.esteClient,
        esteParteAdversa: parte.esteParteAdversa,
      };

      const response = await fetch(`${process.env.REACT_APP_API_URL}updateParte/${parte.idparte}?idStadiuDosar=${idStadiuDosar}&idCalitate=${newCalitate}&esteClient=${parte.esteClient}&esteParteAdversa=${parte.esteParteAdversa}`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data),
      });

      if (response.status === 200) {
        console.log(`Partea cu ID ${parte.idparte} a fost actualizată cu succes.`);
        return true;
      } else {
        console.error(`Eroare la actualizarea părții cu ID ${parte.idparte}`);
        return false;
      }
    } catch (error) {
      console.error('Error:', error);
      return false;
    }
  };

  //editarea unei parti
  const handleEdit = async (idParte) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}client/${idParte}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
      const data = await response.json();
      if (data.status === 200) {
        const parteaDeEditat = data.data.client;
        const estePersoanaJuridica = Boolean(parteaDeEditat.cui && parteaDeEditat.cui.trim().length > 0);
  
        // Găsim idCalitate din partidosar
        const parteDosar = partidosar.find(parte => parte.idparte === idParte);
        const idCalitate = parteDosar ? parteDosar.idcalitate : '';
  
        setParteaDeEditat({
          ...parteaDeEditat,
          idcalitate: idCalitate
        });
  
        setEstePersoanaJuridica(estePersoanaJuridica);
        setFormData({
          numeprenume: parteaDeEditat.numeprenume || '',
          societate: parteaDeEditat.societate || '',
          cui: parteaDeEditat.cui || '',
          registrucomert: parteaDeEditat.registrucomert || '',
          iban: parteaDeEditat.iban || '',
          banca: parteaDeEditat.banca || '',
          adresa: parteaDeEditat.adresa || '',
          telefon: parteaDeEditat.telefon || '',
          email: parteaDeEditat.email || '',
          idCalitate: idCalitate,  
          estePersoanaJuridica: estePersoanaJuridica,
        });
  
        handleEditModal();
      } else {
        console.error('Eroare la încărcarea datelor pentru editare');
        Swal('Atenționare', 'Nu s-au putut încărca datele pentru editare.', 'warning');
      }
    } catch (error) {
      console.error('Error:', error);
      Swal('Atenționare', 'A apărut o eroare la încărcarea datelor pentru editare.', 'warning');
    }
  };
  
  

  const handleUpdateParte = () => {
    const formIsValid = validateForm(); 
    if (formIsValid) {
      handleUpdateParte2();
    }
  };

  const handleUpdateParte2 = async () => {
    // Construim baza URL-ului
    let url = `${process.env.REACT_APP_API_URL}parti/${parteaDeEditat.id}?`;
  
    // Adăugăm parametrii comuni
    url += `numeprenume=${encodeURIComponent(formData.numeprenume)}&`;
    url += `telefon=${encodeURIComponent(formData.telefon)}&`;
    url += `email=${encodeURIComponent(formData.email)}&`;
    url += `idCalitate=${encodeURIComponent(formData.idCalitate)}&`;
    url += `esteClient=${encodeURIComponent(formData.esteClient)}&`;
    url += `esteParteAdversa=${encodeURIComponent(formData.esteParteAdversa)}&`;
  
    // Adăugăm parametrii specifici în funcție de tipul de persoană
    if (formData.estePersoanaJuridica) {
      // Persoană juridică
      url += `societate=${encodeURIComponent(formData.societate)}&`;
      url += `cui=${encodeURIComponent(formData.cui)}&`;
      url += `registrucomert=${encodeURIComponent(formData.registrucomert)}&`;
      url += `iban=${encodeURIComponent(formData.iban)}&`;
      url += `banca=${encodeURIComponent(formData.banca)}&`;
      url += `adresa=${encodeURIComponent(formData.adresa)}&`;
      // Trimitem CNP null pentru persoană juridică
      url += `cnp=`;
    } else {
      // Persoană fizică
      url += `cnp=${encodeURIComponent(formData.cnp)}&`;
      url += `adresa=${encodeURIComponent(formData.adresa)}&`;
      // Trimitem valorile null pentru câmpurile specifice persoanei juridice
      url += `societate=&cui=&registrucomert=&iban=&banca=`;
    }
  
    try {
      const response = await fetch(url, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      });
      if (response.status === 200) {
        console.log('Partea a fost actualizată cu succes.');
        Swal('Succes', 'Partea a fost actualizată cu succes.', 'success');
        handleClose(); // Închide dialogul de editare
        setParteaDeEditat(null); 
        setEstePersoanaJuridica(false);
        setOpen(false);
        fetchData(); // Reîmprospătează lista părților
      } else if (response.status === 422 && response.message) {
        const errorMessages = Object.values(response.message).flat().join('\n');
        Swal('Atenționare', errorMessages, 'warning');
      } else {
        console.error('Eroare la actualizarea părții');
        Swal('Atenționare', 'A apărut o eroare la actualizarea părții.', 'warning');
      }
    } catch (error) {
      console.error('Error:', error);
      Swal('Atenționare', 'A apărut o eroare la actualizarea părții.', 'warning');
    }
  }; 

  const handleShowParteDetails = async (idParte) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}client/${idParte}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    });
    const data = await response.json();
    if (data.status === 200) {
      setSelectedParte(data.data.client);
      setOpenView(true);  
    } else {
      console.error('Eroare la încărcarea datelor părții');
      Swal('Atenționare', 'Nu s-au putut încărca datele părții.', 'warning');
    }
  };
  
  
useEffect(() => {
  if (parteaDeEditat) {
    setFormData({
      numeprenume: parteaDeEditat.numeprenume || '',
      societate: parteaDeEditat.societate || '',
      cui: parteaDeEditat.cui || '',
      registrucomert: parteaDeEditat.registrucomert || '',
      iban: parteaDeEditat.iban || '',
      banca: parteaDeEditat.banca || '',
      adresa: parteaDeEditat.adresa || '',
      telefon: parteaDeEditat.telefon || '',
      email: parteaDeEditat.email || '',
      idCalitate: parteaDeEditat.idcalitate || '',
      //estePersoanaJuridica: parteaDeEditat.cui ? true : false,
      estePersoanaJuridica: parteaDeEditat.estePJ,
    });
    
    // Deschide dialogul de editare
    setOpen(true);
  }
}, [parteaDeEditat, calitati]);

const handleBifarePartiAdverse = async () => {
  const updatedPartidosar = partidosar.map(parte => {
    if (!parte.esteClient) {
      return { ...parte, esteParteAdversa: true };
    }
    return parte;
  });

  setPartidosar(updatedPartidosar);
  setIsExecuting(true);
  try {
    const promises = updatedPartidosar
      .filter(parte => parte.esteParteAdversa !== parte.originalEsteParteAdversa && !parte.esteClient)
      .map(parte => {
        return fetch(`${process.env.REACT_APP_API_URL}updateParte/${parte.idparte}?idStadiuDosar=${idStadiuDosar}&idCalitate=${parte.idcalitate}&esteClient=${parte.esteClient}&esteParteAdversa=true`, {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ 
            esteClient: parte.esteClient,
            esteParteAdversa: true,
           })
        });
      });

    await Promise.all(promises);
    setIsExecuting(false);
    Swal('Succes', 'Toate părțile adverse au fost bifate.', 'success');
  } catch (error) {
    setIsExecuting(false);
    console.error('Eroare la actualizarea părților:', error);
    Swal('Eroare', 'A apărut o eroare la bifarea părților adverse.', 'error');
  }
};


const handleDebifarePartiAdverse = async () => {
  const updatedPartidosar = partidosar.map(parte => {
    if (parte.esteParteAdversa) {
      return { ...parte, esteParteAdversa: false };
    }
    return parte;
  });

  setPartidosar(updatedPartidosar);
  setIsExecuting(true);
  try {
    
    const promises = updatedPartidosar
      .filter(parte => parte.esteParteAdversa !== parte.originalEsteParteAdversa)
      .map(parte => {
        return fetch(`${process.env.REACT_APP_API_URL}updateParte/${parte.idparte}?idStadiuDosar=${idStadiuDosar}&idCalitate=${parte.idcalitate}&esteClient=${parte.esteClient}&esteParteAdversa=${parte.esteParteAdversa}`, {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ 
            esteClient: parte.esteClient,
            esteParteAdversa: parte.esteParteAdversa,
           })
        });
      });

    await Promise.all(promises);
    setIsExecuting(false);
    Swal('Succes', 'Toate părțile adverse au fost debifate.', 'success');
  } catch (error) {
    setIsExecuting(false);
    console.error('Eroare la actualizarea părților:', error);
    Swal('Eroare', 'A apărut o eroare la debifarea părților adverse.', 'error');
  }
};

const handleAdaugaMonitorizare = async (cui) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}adaugamonitorizare?cui=${cui}&iddosar=${id}`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (response.ok) {
      Swal('Succes', 'Parte monitorizată cu succes în BPI.', 'success');
      fetchData();
    } else {
      Swal('Atenție', 'Partea nu a putut fi monitorizată în BPI.', 'warning');
    }
  } catch (error) {
    console.error('Error:', error);
    Swal('Atenție', 'Partea nu a putut fi monitorizată în BPI.', 'warning');
  }
};

const handleStergeMonitorizare = async (idmonitorizare) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}stergemonitorizare/${idmonitorizare}`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (response.ok) {
      Swal('Succes', 'Parte eliminată de la monitorizare din BPI cu succes.', 'success');
      fetchData();
    } else {
      Swal('Atenție', 'Partea nu a putut fi eliminată de la monitorizare BPI.', 'warning');
    }
  } catch (error) {
    console.error('Error:', error);
    Swal('Atenție', 'Partea nu a putut fi eliminată de la monitorizare BPI.', 'warning');
  }
};


  return (
    <div>
      {loading ? (
        <div style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '10vh' 
        }}>
          <CircularProgress />
        </div>
      ) : (
        <div>
          <div style={{width:'100%', display:'block', height:'50px'}}>
          {areDrept(DreptDeModificareDosare) && (
            <>
            <Button
                variant="contained"
                color="primary"
                onClick={handleBifarePartiAdverse}
                disabled={partidosar.every(parte => parte.esteParteAdversa || parte.esteClient)}
                style={{ marginTop: '10px', marginLeft: '10px', minWidth: '200px', float: 'right' }}
                size="small"
              >
                Bifare părți adverse
              </Button>

            <Button
              variant="contained"
              color="warning"
              onClick={handleDebifarePartiAdverse}
              disabled={partidosar.every(parte => !parte.esteParteAdversa)}
              style={{ marginTop: '10px', marginLeft: '10px', minWidth:'200px', float:'right' }}
              size="small"
            >
              Debifare părți adverse
            </Button>
            </>
         )}  
       {areDrept(DreptDeModificareDosare) ? ( 
            <Button
                disabled={!areDrept(DreptDeModificareDosare)}
                variant="contained"
                color="secondary"
                startIcon={<AddIcon />}
                style={{ marginTop: '10px', minWidth:'200px', float:'right' }}
                onClick={handleOpen} 
                size="small"
              >Adăugare Parte</Button> 
            ) : null }
            

      <FormControl style={{ minWidth: '200px', marginLeft: '10px', float:'left' }}>
        <InputLabel id="stadiuDosar-label">Stadiu Dosar</InputLabel>
        <Select
          size="small"
          labelId="stadiuDosar-label"
          label="Stadiu Dosar"
          value={idStadiuDosar}
          onChange={handleDropdownChange}
        >
          {dropdownOptions.map((option) => (
            <MenuItem key={option.id} value={option.id}>
              Stadiu {option.numeStadiu} (Data: {formatData(option.data)})
            </MenuItem>
          ))}
        </Select>
      </FormControl>
          </div>
        {loadingParti ? (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '10vh' }}>
            <CircularProgress />
          </div>
        ) : (
          partidosar.map((parte) => (
            <Box key={parte.idparte} mb={3} display="flex" justifyContent="space-between" margin="20px 0" padding="10px" border="1px solid gray" borderRadius="5px" className="parteCont">
              <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                <div style={{ flex: 8 }}>
                  <span onClick={() => handleShowParteDetails(parte.idparte)} style={{ cursor: 'pointer' }}>
                    {parte.denumire} ({parte.numecalitate}){parte.societate ? ` / ${parte.societate}` : ''}
                  </span> 
                  <br /> 
                  <FormControlLabel
                    control={
                      <Checkbox
                        disabled={!areDrept(DreptDeModificareDosare)}
                        name="esteClient"
                        color="primary"
                        checked={parte.esteClient}
                        onChange={(e) => handleInputChange(e, parte)}
                      />
                    }
                    label="Client"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        disabled={!areDrept(DreptDeModificareDosare) || parte.esteClient} 
                        name="esteParteAdversa"
                        color="primary"
                        checked={parte.esteParteAdversa}
                        onChange={(e) => handleInputChange(e, parte)}
                      />
                    }
                    label="Parte adversă"
                  /> 
                  <FormControlLabel
                    control={
                      <Checkbox
                        disabled={!parteDosar} 
                        name="esteParteDosar"
                        color="primary"
                        checked
                      />
                    }
                    label="Parte dosar"
                  /> 
                </div>

                {areDrept(DreptDeModificareDosare) ? ( 
                  <div style={{ flex: 4, display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                    <FormControl style={{ minWidth: '200px', marginLeft: '10px' }}>
                      <InputLabel id={`calitate-label-${parte.idparte}`}>Calitate</InputLabel>
                      <Select
                        disabled={!areDrept(DreptDeModificareDosare)}
                        size='small'
                        labelId={`calitate-label-${parte.idparte}`}
                        label="Calitate"
                        value={parte.idcalitate}
                        onChange={(event) => handleCalitateChange(event, parte)}
                      >
                        {parte.calitati.map((calitate) => (
                          <MenuItem key={calitate.id} value={calitate.id}>{calitate.denumire}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>

                    {parte.estePJ && (
                      <Tooltip title={parte.esteBpi ? 'Scoateți de la monitorizare BPI' : 'Adăugați la monitorizare BPI'}>
                        <IconButton
                          onClick={() => {
                            if (parte.esteBpi) {
                              handleStergeMonitorizare(parte.idFirmaMonitorizata);
                            } else {
                              handleAdaugaMonitorizare(parte.cui);
                            }
                          }}
                          color={parte.esteBpi ? 'primary' : 'default'}
                        >
                          {parte.esteBpi ? <VisibilityOffIcon /> : <VisibilityIcon />}
                        </IconButton>
                      </Tooltip>
                    )}

                    
                    <IconButton onClick={() => handleShowParteDetails(parte.idparte)} color="secondary">
                     <InfoIcon />
                    </IconButton>
                    <IconButton aria-label="edit" disabled={!areDrept(DreptDeModificareDosare)} onClick={() => handleEdit(parte.idparte)} color="secondary">
                      <EditIcon />
                    </IconButton>
                    <IconButton  disabled={!areDrept(DreptDeModificareDosare) || parte.esteClient}       color="secondary" onClick={() => handleDelete(parte.idparte, parte.idstadiudosar)}>
                      <DeleteIcon />
                    </IconButton>
                  </div>
                ) : null } 
              </div>

              </Box>
            )) 

        )}

<Dialog open={open} aria-labelledby="form-dialog-title">
    <DialogTitle id="form-dialog-title" className='titluModal'>
      {parteaDeEditat ? 'Editare Parte' : 'Adăugare Parte'}
      <IconButton 
        aria-label="close" 
        onClick={handleClose} 
        style={{ position: 'absolute', right: 8, top: 8 }}
      >
        <CloseIcon />
      </IconButton>
    </DialogTitle>
      <DialogContent>
        <FormControlLabel
          control={
            <Checkbox
              checked={estePersoanaJuridica}
              onChange={handleSwitchChange}
              name="estePersoanaJuridica"
              color="primary"
            />
          }
          label="Este persoană juridică?"
        />
        {estePersoanaJuridica ? (
          // Pentru persoane juridice
          <>
          
            <TextField
              autoFocus
              margin="dense"
              id="numeprenume"
              label="Reprezentant legal"
              fullWidth
              name="numeprenume"
              value={formData.numeprenume}
              onChange={handleInputChange}
              error={Boolean(errors.numeprenume)}
              helperText={errors.numeprenume}
            />
            <TextField
              margin="dense"
              id="societate"
              label="Denumire societate"
              fullWidth
              name="societate"
              value={formData.societate}
              onChange={handleInputChange}
              error={Boolean(errors.societate)}
              helperText={errors.societate}
              InputLabelProps={{ shrink: true }}
            />
            <TextField
              margin="dense"
              id="cui"
              label="CUI"
              fullWidth
              name="cui"
              value={formData.cui}
              onChange={handleInputChange}
              error={Boolean(errors.cui)}
              helperText={errors.cui}
            />
            <TextField
              margin="dense"
              id="registrucomert"
              label="Registru Comerț"
              fullWidth
              name="registrucomert"
              value={formData.registrucomert}
              onChange={handleInputChange}
              error={Boolean(errors.registrucomert)}
              helperText={errors.registrucomert}
            />
            <TextField
              margin="dense"
              id="iban"
              label="IBAN"
              fullWidth
              name="iban"
              value={formData.iban}
              onChange={handleInputChange} 
            />
            <TextField
              margin="dense"
              id="banca"
              label="Banca"
              fullWidth
              name="banca"
              value={formData.banca}
              onChange={handleInputChange}
              error={Boolean(errors.banca)}
              helperText={errors.banca}
            />
            
            <TextField
              margin="dense"
              id="adresa"
              label="Adresa"
              fullWidth
              name="adresa"
              value={formData.adresa}
              onChange={handleInputChange}
              error={Boolean(errors.adresa)}
              helperText={errors.adresa}
            />
            <TextField
              margin="dense"
              id="telefon"
              label="Telefon"
              fullWidth
              name="telefon"
              value={formData.telefon}
              onChange={handleInputChange}
              error={Boolean(errors.telefon)}
              helperText={errors.telefon}
            />
            <TextField
              margin="dense"
              id="email"
              label="E-mail"
              fullWidth
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              error={Boolean(errors.email)}
              helperText={errors.email}
            />
          </>
        ) : (
          // Pentru persoane fizice
          <>
            <TextField
              autoFocus
              margin="dense"
              id="numeprenume"
              label="Nume Prenume"
              fullWidth
              name="numeprenume"
              value={formData.numeprenume}
              onChange={handleInputChange}
              error={Boolean(errors.numeprenume)}
              helperText={errors.numeprenume}
            />
            <TextField
              margin="dense"
              id="cnp"
              label="CNP"
              fullWidth
              name="cnp"
              value={formData.cnp}
              onChange={handleInputChange}
              error={Boolean(errors.cnp)}
              helperText={errors.cnp}
            />
            <TextField
              margin="dense"
              id="adresa"
              label="Adresa"
              fullWidth
              name="adresa"
              value={formData.adresa}
              onChange={handleInputChange}
              error={Boolean(errors.adresa)}
              helperText={errors.adresa}
            />
            <TextField
              margin="dense"
              id="telefon"
              label="Telefon"
              fullWidth
              name="telefon"
              value={formData.telefon}
              onChange={handleInputChange}
              error={Boolean(errors.telefon)}
              helperText={errors.telefon}
            />
            <TextField
              margin="dense"
              id="email"
              label="E-mail"
              fullWidth
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              error={Boolean(errors.email)}
              helperText={errors.email}
            />
          </>
        )}

      <FormControl fullWidth required error={Boolean(errors.idCalitate)} className='mt-3'>
        <InputLabel id="calitate-label">Calitate</InputLabel>
        <Select
          labelId="calitate-label"
          id="calitate"
          name="idCalitate"
          value={formData.idCalitate || ''}
          onChange={(event) => handleInputChange(event)}
          label="Calitate"
        >
          {calitati.map((calitate) => (
            <MenuItem key={calitate.id} value={calitate.id}>{calitate.denumire}</MenuItem>
          ))}
        </Select>
        {errors.idCalitate && <FormHelperText>{errors.idCalitate}</FormHelperText>}
      </FormControl>

      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="error">
          Anulare
        </Button>
        <Button onClick={parteaDeEditat ? handleUpdateParte : handleSubmitModified} disabled={!isFormValid}>
           {parteaDeEditat ? 'Salvare modificări' : 'Adăugare'} 
        </Button>
      </DialogActions>
    </Dialog>
 
    <Dialog open={openView} onClose={handleCloseView} aria-labelledby="form-dialog-title" maxWidth="sm" fullWidth>
        <DialogTitle id="form-dialog-title" className='titluModal'>
          Detalii Parte
          <IconButton 
          aria-label="close" 
          onClick={handleCloseView} 
          style={{ position: 'absolute', right: 8, top: 8 }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
        <DialogContent>
        {selectedParte ? (
            <div className='mt-3'>
              <b>{selectedParte.cui || selectedParte.registrucomert ? 'Persoană Juridică' : 'Persoană Fizică'}</b>
              <p>Nume/Prenume: {selectedParte.numeprenume}</p>
              {selectedParte.cui || selectedParte.registrucomert ? (
                <>
                  <p>Societate: {selectedParte.societate}</p>
                  <p>CUI: {selectedParte.cui}</p>
                  <p>Registru Comerț: {selectedParte.registrucomert}</p>
                </>
              ) : (
                <p>CNP: {selectedParte.cnp}</p>
              )}
              <p>Adresa: {selectedParte.adresa}</p>
              <p>Telefon: {selectedParte.telefon}</p>
              <p>Email: {selectedParte.email}</p>
              {selectedParte.cui || selectedParte.registrucomert ? (
                <>
                  <p>Banca: {selectedParte.banca}</p>
                  <p>IBAN: {selectedParte.iban}</p>
                </>
              ) : null}
            </div>
          ) : (
            <p>Nu sunt disponibile detalii pentru această parte.</p>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseView} color="primary">
            Închide
          </Button>
        </DialogActions>
      </Dialog>


        </div>
      )}
      {isExecuting && (
        <div style={{
            position: 'fixed', top: 0, left: 0, width: '100%', height: '100%',
            display: 'flex', justifyContent: 'center', alignItems: 'center',
            backgroundColor: 'rgba(0, 0, 0, 0.5)', zIndex: 999
        }}>
            <div style={{ textAlign: 'center' }}>
                <CircularProgress size={80} />
                <Typography variant="h5" style={{ color: 'white', marginTop: 20 }}>
                    Se procesează, vă rugăm așteptați...
                </Typography>
            </div>
        </div>
    )}
    </div>
  );
}

export default PartiDosar;
  